import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { BiLoader } from "react-icons/bi";
import { MdClose } from "react-icons/md";
import "../styles/FormStyles.css";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { populateProfile } from "../redux/profile";
import { updateLogin } from "../redux/loggedIn";
import { useState } from "react";
import { findAccount } from "../services/fetchRequests";
import { openModal } from "../redux/modalSlice";

// function to get the closest element with a particular class
const getClosest = function (elem, selector) {
  // Element.matches() polyfill
  if (!Element.prototype.matches) {
    Element.prototype.matches =
      Element.prototype.matchesSelector ||
      Element.prototype.mozMatchesSelector ||
      Element.prototype.msMatchesSelector ||
      Element.prototype.oMatchesSelector ||
      Element.prototype.webkitMatchesSelector ||
      function (s) {
        var matches = (this.document || this.ownerDocument).querySelectorAll(s),
          i = matches.length;
        while (--i >= 0 && matches.item(i) !== this) { }
        return i > -1;
      };
  }

  // Get the closest matching element
  for (; elem && elem !== document; elem = elem.parentNode) {
    if (elem.matches(selector)) return elem;
  }
  return null;
};

// function to hide the login error message when the close icon is clicked
function hide(e) {
  // get the parent of the close icon
  if (e.target.parentElement.classList.contains("form-message__close")) {
    const parent = getClosest(e.target.parentElement, ".form-message");
    parent.classList.add("hide"); // add the hide class to make it disappear
  }
}

// component that displays message when form is submitted
function FormMessage(props) {
  const state = { ...props.state };
  const setState = props.setState;
  // const dispatch = useDispatch();
  return (
    <div
      className={`form-message border border-red-500 rounded-lg bg-white p-4 ${state.status ? "" : "hidden"
        }`}
    >
      {/* title and close button */}
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-1">
          <span className="text-base text-red-600">
            <AiOutlineExclamationCircle />
          </span>
          <p className="text-sm text-red-700 font-semibold">
            Something went wrong
          </p>
        </div>

        <span
          onClick={(e) => setState({ status: false })}
          className="form-message-close justify-self-end cursor-pointer"
        >
          <MdClose />
        </span>
      </div>

      {/* Error */}
      {/* {state.code ? <p className="text-xs pl-2 pt-2  text-gray-600">{state.code}</p> : ''} */}

      {/* message */}
      {state.message ? (
        <p className="text-xs pl-2 pt-2  text-gray-600">{state.message}</p>
      ) : (
        ""
      )}

      {state.code && state.code === "V-300" ? (
        <p className="text-xs pl-2 pt-2  text-gray-600">
          You may already have an account.{" "}
          {/* <span onClick={() => dispatch(openModal("accountCheck"))}> */}
          <span>Click here</span> to check and retrieve your username and
          password
        </p>
      ) : (
        ""
      )}

      {state.code === "unknown" ? (
        <p className="text-xs pl-2 pt-2  text-gray-600">
          An unknown error occured.{" "}
          <a
            href={`https://wa.me/2349030009002?text=Hi%2C%20%0A%0AI%20am%20experiencing%20technical%20while%20trying%20to%20create%20an%20ABUAD%20alumni%20account`}
            alt="help"
          >
            Click here
          </a>{" "}
          to contact help{" "}
        </p>
      ) : (
        ""
      )}
    </div>
  );
}

const LoginForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [errorMsg, setErrorMsg] = useState({
    status: false,
    message: false,
    code: false,
  });
  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
      }}
      validationSchema={Yup.object({
        email: Yup.string().trim().email("Invalid email").required(`Required`),
        password: Yup.string().trim().required("Required"),
      })}
      onSubmit={(values, { setSubmitting }) => {
        // make api request search for profile in database
        Promise.resolve(findAccount(values.email, values.password)).then(
          function (result) {
            if (result.status === true) {
              // populate the profile store with the data in the response
              dispatch(updateLogin(true));
              dispatch(populateProfile(result.alumnus));
              history.push("/menu");
              setSubmitting(false);
            } else {
              setErrorMsg({ status: true, message: result.message });
              setSubmitting(false);
            }
          }
        );
      }}
    >
      {({ values, isSubmitting }) => (
        <Form className="form login-form">
          <div>
            <h1 className="text-primary font-semibold text-base md:text-xl">
              Welcome
            </h1>
            <h2 className="text-gray-500 text-sm">
              Login in to access your account
            </h2>
          </div>
          {/* Email */}
          <div>
            <div className="form-group">
              <label className="form-group__label sr-only" htmlFor="email">
                Email
              </label>
              <Field
                className="form-group__input"
                name="email"
                type="text"
                placeholder="Email"
              />
            </div>
            <ErrorMessage
              render={(msg) => (
                <div className="form-group__error-message">{msg}</div>
              )}
              name="email"
            />
          </div>

          {/* Password */}
          <div>
            <div className="form-group">
              <label className="form-group__label sr-only" htmlFor="password">
                Password
              </label>
              <Field
                className="form-group__input"
                name="password"
                type="password"
                placeholder="Password"
              />
            </div>
            <ErrorMessage
              render={(msg) => (
                <div className="form-group__error-message">{msg}</div>
              )}
              name="password"
            />
          </div>

          <div id="login-message" className="form-message hide">
            <div className="form-message__top ">
              <div className="form-message__notification">
                <span className="form-message__icon">
                  <AiOutlineExclamationCircle />
                </span>
                <p className="form-message__title">Error</p>
              </div>
              <span onClick={(e) => hide(e)} className="form-message__close">
                <MdClose />
              </span>
            </div>

            <div className="form-message__message">
              User not found.{" "}
              <a href="https://alumni.abuad.edu.ng/signup">Click here</a> to
              signup or click "forgot your password" below to retrieve your
              password.
            </div>
          </div>

          <FormMessage state={errorMsg} setState={setErrorMsg} />

          <span
            className=" flex items-center text-xs text-gray-500 cursor-pointer"
            onClick={() => dispatch(openModal('accountCheck'))}
          >
            {" "}
            <span className="mr-2 text-red-400">
              <AiOutlineExclamationCircle />
            </span>{" "}
            Recover my password
          </span>

          <button
            id="login-submit"
            className="text-sm bg-primary items-center transition duration-300 hover:bg-primary-light text-center text-white font-medium py-2 px-4  rounded-md focus:outline-none"
            type="submit"
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <span className="text-lg flex justify-center items-center space-x-1">
                <BiLoader />
                <p className="text-sm">Loading</p>
              </span>
            ) : (
              "Login"
            )}
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
