import '../styles/appBarBottom.css';
import {FaHome, FaSearch} from 'react-icons/fa';
import {BsPersonFill} from 'react-icons/bs';
import { Link, matchPath, useHistory } from 'react-router-dom';

const AppBarBottom = () => {
  const history = useHistory();
  // console.log(matchPath(history.location.pathname, '/profile')?.isExact);

  function checkPath(path){
    return matchPath(history.location.pathname, path)?.isExact
  }

  return (
    <div className="appbar-b">
      <Link to='/directory' className={`appbar-b__link ${checkPath('/directory') ? 'selected' : '' }`} ><FaHome/><span className="appbar-b__link-name">Dashboard</span></Link>
      <Link to='/directory/search' className={`appbar-b__link ${checkPath('/directory/search') ? 'selected' : '' }`}><FaSearch/><span className="appbar-b__link-name">Search</span></Link>
      <Link to='/profile' className={`appbar-b__link ${checkPath('/profile') ? 'selected' : '' }`}><BsPersonFill/><span className="appbar-b__link-name">Profile</span></Link>
    </div>
  );
}

export default AppBarBottom;