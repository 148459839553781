
import defaultImage from "../../../img/userImage.png";
import { useRef, useState } from "react";

const RenderImage_hook = (image, setDbImage) =>{
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
	const [showCropper, setShowCropper] = useState(false);
	const handleCropper = () => setShowCropper( (prevValue) => !prevValue)
	const [displayImage, setDisplayImage] = useState(image ?? defaultImage);

	const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  return {
    displayImage,
    setOpen,
    anchorRef,
    open,
    handleToggle,
    showCropper,
    setShowCropper,
    handleCropper,
    setDisplayImage,
    handleClose,
    handleListKeyDown
  }
}

export default RenderImage_hook;