import LoginForm from './LoginForm';
import tw from "tailwind-styled-components";
import "../styles/loginPageStyles.css";

const LoginStyle = tw.div`
  grid
  grid-rows-2
  md:h-screen
  md:grid-cols-2
`;

const Login = () => {
  return (
    <LoginStyle>
      <div className="login-message">
          <p className="login-message__head">Sign in</p>
          <h3 className="login-message__body">
            Exclusive to Afe Babalola University graduates
          </h3>
          <p className="login-message__sub">
            Connect with your old classmates, request transcripts and enjoy other
            services we will be adding in the future.
          </p>
      </div>
      <LoginForm  />
    </LoginStyle>
  );
}

export default Login;
