import { createSlice } from '@reduxjs/toolkit'

export const modalSlice = createSlice({
  name: 'modals',
  initialState: {
    accountCheck: {display : false, state : 'form', details: {}},
  },
  reducers: {
    openModal:(state, action)=>{
      switch(action.payload){
        //  set all other modal state to false when one is set to open
        case 'accountCheck':
          state.accountCheck.display = true;
          break;
        // case 'account':
        //   state.accountCheck.display = false;
        //   break;
        // case 'productOption':
        //   state.accountCheck.display = false;
        //   break;
        // case 'menubar':
        //   state.accountCheck.display = false;
        //   break;
        default:
          state.accountCheck.display = true;
      }
    },
    setUpdateState:(state, action) =>{
      // state.updateState = action.payload
    },
    closeModal:(state, action)=>{
      state[action.payload]['display'] = false;
    }
  },
})

// Action creators are generated for each case reducer function
export const { openModal, closeModal, setUpdateState } = modalSlice.actions

export default modalSlice.reducer