import '../styles/alumnus.css';
import Nav from './Nav';
import {Link} from 'react-router-dom';
import businessImage from "../img/storeImage.jpg";
import { MdLocationOn } from "react-icons/md";
import { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { useSelector } from 'react-redux';
import { getSimilarBusinesses } from '../services/fetchRequests';



const SingleBusiness = (props) => {
  const loggedIn = useSelector(state => state.loggedIn);
  const profile = props.location.state.business;

  const { pathname } = useLocation();

    // set state for the alumnus coursemates;
    const [similarBusinesses, setSimilarBusinesses] = useState([]);

    // after page load, load the alumnus' friends
    useEffect( () =>{
      Promise.all([getSimilarBusinesses(profile.industry)])
      .then((values)=>{
       const [ one ] = values;
       setSimilarBusinesses(one); // set coursemates to the returned objec
      })
    },[profile.industry]);


  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  // go to login page if not logged in
  if(loggedIn.value === false){
    return <Redirect to='/'/>
  }

  return (
    <div>
      <Nav/>
      <div className="alumnus  bg-gray-100">

        <div className="alumnus__header">
          <span className="alumnus__header-location"><MdLocationOn/>{profile.location || "-"}</span>
          <div className="flex space-x-2 md:space-x-4 items-center">
            <div className="image" >
              <img src={profile.image || businessImage} alt="profile"/>
            </div>

            <div className="name-div">
              <p className="text-base font-bold text-primary-dark">{profile.name}</p>
              <p className="text-sm"><span className="text-sm font-medium text-gray-700">EMPLOYEES:</span> {profile.employeeNumber}</p>
              {profile.industry ? <p className="text-sm"> <span className="text-sm font-medium text-gray-700">INDUSTRY: </span>{profile.industry}</p> : ''}
            </div>
          </div>

          <div>
            <h2 className="text-primary-dark  font-bold text-base mb-3">About</h2>
            <div className="flex flex-col rounded-md space-y-3 md:w-full md:flex-row md:space-y-0 md:space-x-4">
              <div className="p-2 flex flex-col bg-white rounded-md md:w-4/12 md:h-full">
                <span className="text-sm text-gray-700">{profile.description }</span>
              </div>
            </div>
            
          </div>

          <div>
            <h2 className="text-primary-dark  font-bold text-base mb-3">Services</h2>
            <div className="flex flex-col rounded-md space-y-3 md:w-full md:flex-row md:space-y-0 md:space-x-4">
              <div className="p-2 flex flex-col bg-primary rounded-md md:w-4/12 md:h-full">
                <span className="text-sm text-white">{profile.service1 }</span>
              </div>

              <div className="p-2 flex flex-col bg-primary rounded-md md:w-4/12 md:h-full">
                <span className="text-sm text-white">{profile.service2 }</span>
              </div>

              <div className="p-2 flex flex-col bg-primary rounded-md md:w-4/12 md:h-full">
                <span className="text-sm text-white">{profile.service3 }</span>
              </div>

              <div className="p-2 flex flex-col bg-primary rounded-md md:w-4/12 md:h-full">
                <span className="text-sm text-white">{profile.service4 }</span>
              </div>
            </div>
            
          </div>

          <div className="">
            <h2 className="text-primary-dark  font-bold text-base mb-3">Contact</h2>
            <div className="flex flex-col rounded-md space-y-3 md:w-full md:flex-row md:space-y-0 md:space-x-4">
              <div className="p-2 flex flex-col bg-white rounded-md md:w-4/12 md:h-full">
                <span className="text-primary font-semibold text-xs">Email</span>
                <span className="text-sm text-gray-600">{profile.email }</span>
              </div>

              <div className="p-2 flex flex-col bg-white rounded-md md:w-4/12 md:h-full">
                <span className="text-primary font-semibold text-xs">Website</span>
                <span className="text-sm text-gray-600">{profile.website}</span>
              </div>

              <div className="p-2 flex flex-col bg-white rounded-md md:w-4/12 md:h-full">
                <span className="text-primary font-semibold text-xs">Phone</span>
                <span className="text-sm text-gray-600">{profile.phone }</span>
              </div>

              <div className="p-2 flex flex-col bg-white rounded-md md:w-4/12 md:h-full">
                <span className="text-primary font-semibold text-xs">Whatsapp</span>
                <span className="text-sm text-gray-600">{profile.whatsapp }</span>
              </div>

              <div className="p-2 flex flex-col bg-white rounded-md md:w-4/12 md:h-full">
                <span className="text-primary font-semibold text-xs">Address</span>
                <span className="text-sm text-gray-600">{profile.address || '-' }</span>
              </div>

              <div className="p-2 flex flex-col bg-white rounded-md md:w-4/12 md:h-full">
                <span className="text-primary font-semibold text-xs">Twitter</span>
                <span className="text-sm text-gray-600">{profile.twitter || '-' }</span>
              </div>

              <div className="p-2 flex flex-col bg-white rounded-md md:w-4/12 md:h-full">
                <span className="text-primary font-semibold text-xs">Instagram</span>
                <span className="text-sm text-gray-600">{profile.instagram || '-' }</span>
              </div>
            </div>
          </div>
        </div>

        <div className="alumnus__body">
          <div className="alumnus__collegues">
          <h4 className="title p-sm">Businesses similar to {profile.name}</h4>

          <div className="alumnus__collegues-inner">

            {
              similarBusinesses.map( (business, i) => (
                <Link className="item" key={business.id} to={{pathname: `/businesses/business/${business.id}`, state:{business: business}}} >
                  <img className="image" src={business.image || businessImage} alt="user" />
                  <span className="name p-xs">{business.name}</span>
                  <span className="username p-xs">{business.industry}</span>
                  <span className="location">
                    <MdLocationOn />
                    {business.location || "-"}
                  </span>
                </Link>
              )) 
            }
            
          </div>
        </div>
        </div>
        
      </div>
    </div>
  );
}

export default SingleBusiness;
