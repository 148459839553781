import { configureStore } from "@reduxjs/toolkit";
import profileReducer from "./profile";
import businessReducer from "./business";
import loggedInReducer from "./loggedIn";
import modalReducer from './modalSlice';

export default configureStore({
  reducer: {
    profile: profileReducer,
    business: businessReducer,
    loggedIn: loggedInReducer,
    modals: modalReducer,
  },
});
