import "../styles/directory.css";
import {
  FaBuilding,
  FaCity,
  FaGraduationCap,
  FaUserTie,
  FaRibbon,
} from "react-icons/fa";
import AppBarBottom from "./AppBarBottom";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import Nav from "./Nav";
import AlumniSearchResult from "./AlumniSearchResult";
import { Redirect } from "react-router-dom";
import {
  getCoursemates,
  getLocationTotal,
  getTotalCoursemates,
} from "../services/fetchRequests";

const Directory = (props) => {
  const { homeLocation, bscCourse, graduationYear } = useSelector(
    (state) => state.profile
  );

  const [totalLocation, setTotalLocation] = useState(0);
  const [totalCoursemates, setTotalCoursemates] = useState(0);
  const [totalIndustry, setTotalIndustry] = useState(0);
  const [totalHonorary, setTotalHonorary] = useState(0);
  const [totalExecutives, setTotalExecutives] = useState(11);
  // const [profileViews, setProfileViews] = useState(1);
  const [coursemates, setCoursemates] = useState([]);

  // get the amount of executives
  // get the amount of honorary members
  // put coursemates inside people you may know folder

  useEffect(() => {
    Promise.all([
      getLocationTotal(homeLocation),
      getTotalCoursemates(bscCourse, graduationYear),
      getCoursemates(bscCourse, graduationYear),
    ]).then((values) => {
      const [one, two, three] = values;
      setTotalLocation(one);
      setTotalCoursemates(two);
      setCoursemates(three);
      setTotalIndustry(0);
      setTotalHonorary(0);
      setTotalExecutives(11);
      // setProfileViews(1);
    });
  }, [homeLocation, bscCourse, graduationYear]);

  // go to login page if not logged in
  if (props.loggedIn.value === false) {
    return <Redirect to="/" />;
  }

  return (
    <div>
      <Nav />
      <div className="px-4 space-y-6 bg-gray-50 md:px-6 lg:px-28 pt-14 pb-20">
        <div className="directory__summary">
          <h2 className="directory__summary-title">Summary</h2>
          <div className="directory__summary-inner">
            <div className="metric selected">
              <span className="title">
                <FaBuilding />
                In your industry
              </span>
              <p className="number">{totalIndustry}</p>
            </div>

            <div className="metric">
              <span className="title">
                <FaCity />
                In your city
              </span>
              <p className="number">{totalLocation}</p>
            </div>

            <div className="metric">
              <span className="title">
                <FaGraduationCap />
                Coursemates
              </span>
              <p className="number">{totalCoursemates}</p>
            </div>

            <div className="metric">
              <span className="title">
                <FaUserTie />
                Executives
              </span>
              <p className="number">{totalExecutives}</p>
            </div>

            <div className="metric">
              <span className="title">
                <FaRibbon />
                Hononary members
              </span>
              <p className="number">{totalHonorary}</p>
            </div>
          </div>
        </div>

        {/* <div className="directory__data">
          <h2 className="directory__data-title">Personal data</h2>

          <div className="directory__data-inner">
            <div className="metric">
              <span className="title">Profile views</span>
              <span className="number">{profileViews}</span>
            </div>

            <div className="metric">
              <span className="title">Requests</span>
              <span className="number">57</span>
            </div>

            <div className="metric">
              <span className="title">Accepted requests</span>
              <span className="number">57</span>
            </div>
          </div>
        </div> */}

        <div>
          <h2 className="mb-4 w-full col-span-full text-base text-primary font-semibold">
            People you may know
          </h2>
          <AlumniSearchResult result={coursemates} />
        </div>
      </div>
      <AppBarBottom />
    </div>
  );
};

export default Directory;
