import Nav from "../components/Nav";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { BiLoader } from "react-icons/bi";
import { MdClose } from "react-icons/md";
import "../styles/profileEditPage.css";
import { Link} from "react-router-dom";
import businessImage from "../img/storeImage.jpg";
import { useSelector} from "react-redux";
import { useState, useEffect } from "react";
import industryList from "../data/job-industries";
import { addBusiness, getCourses} from "../services/fetchRequests";
import degrees from "../data/degrees";
import {FaEnvelopeOpenText} from 'react-icons/fa';


// component that displays message when form is submitted
function FormMessage(props) {
  const state = { ...props.state };
  const setState = props.setState;
  // const dispatch = useDispatch();

  return (
    <div
      className={`form-message border border-red-500 rounded-lg bg-white p-4 ${
        state.status ? "" : "hidden"
      }`}
    >
      {/* title and close button */}
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-1">
          <span className="text-base text-red-600">
            <AiOutlineExclamationCircle />
          </span>
          <p className="text-sm text-red-700 font-semibold">
            Something went wrong
          </p>
        </div>

        <span
          onClick={(e) => setState({ status: false })}
          className="form-message-close justify-self-end cursor-pointer"
        >
          <MdClose />
        </span>
      </div>

      {/* Error */}
      {/* {state.code ? <p className="text-xs pl-2 pt-2  text-gray-600">{state.code}</p> : ''} */}

      {/* message */}
      {state.message ? (
        <p className="text-xs pl-2 pt-2  text-gray-600">{state.message}</p>
      ) : (
        ""
      )}

      {state.code && state.code === "V-300" ? (
        <p className="text-xs pl-2 pt-2  text-gray-600">what fields</p>
      ) : (
        ""
      )}

      {state.code === "unknown" ? (
        <p className="text-xs pl-2 pt-2  text-gray-600">
          An unknown error occured.{" "}
          <a
            href={`https://wa.me/2349030009002?text=Hi%2C%20%0A%0AI%20am%20experiencing%20technical%20while%20trying%20to%20create%20an%20ABUAD%20alumni%20account`}
            alt="help"
          >
            Click here
          </a>{" "}
          to contact help{" "}
        </p>
      ) : (
        ""
      )}
    </div>
  );
}

const BusinessAdd = (props) => {
  const [businessAddState, setBusinessAddState] = useState('form');

  const alumnus = useSelector((state) => state.profile);

  // get profile array from redux
  const [errorMsg, setErrorMsg] = useState({
    status: false,
    message: false,
    code: false,
  });

  // Get and populate all courses
  useEffect(() => {
    // get the courses from the api
    Promise.resolve(getCourses())
      .then((result) => {
        // create empty arrays to categorize courses by degree level
        const bscCourseArray = [];
        const mscCourseArray = [];

        // loop through courses and push them into their arrays
        result.forEach((element) => {
          const degreeId = element.degreeId;
          switch (degrees[degreeId].level) {
            case 1:
              bscCourseArray.push(element);
              break;
            case 2:
              mscCourseArray.push(element);
              break;
            default:
            // code block
          }
        });
      })
      .catch((error) => {});
  }, []);

  if(businessAddState === 'form'){
    return (
      <div className="bg-gray-100">
        <Nav />
        <div className="profile-edit">
          <div className="profile-edit__image">
            <img
              className="profile-edit__image-image"
              src={businessImage}
              alt="profile_image"
            />
            {/* <div className="image"><img className="profile__image-image" src={userImage} alt="profile_image" /></div> */}
          </div>
          <div className="profile-edit__details">
            <Formik
              initialValues={{
                // registrationNumber: "RC23456",
                // employeeNumber: "7",
                // name: "HeGo3ripLTD",
                // description: "Hego3rip is the best website in the world",
                // industry: "Banking and Finance ",
                // service1: "service",
                // service2: "service",
                // service3: "service ",
                // service4: "service",
                // whatsapp: "08066197110",
                // phone: "08066197110",
                // email: "olata@hego3rip.com",
                // twitter: "@hego3rip",
                // instagram: "@hego3rip",
                // image: "",
                // website: "hego3rip.com",
                // address: "",
                // location: "Lagos",
                registrationNumber: "",
                employeeNumber: "",
                name: "",
                description: "",
                industry: "",
                service1: "",
                service2: "",
                service3: "",
                service4: "",
                whatsapp: "",
                phone: "",
                email: "",
                twitter: "",
                instagram: "",
                image: "",
                website: "",
                address: "",
                location: "",
              }}
              validationSchema={Yup.object({
                name: Yup.string().trim().min(2).max(30).required(),
                registrationNumber: Yup.string().trim().min(2).max(30).required(),
                description: Yup.string().trim().min(2).max(300).required(),
                industry: Yup.string().required(),
                employeeNumber: Yup.string().matches("[0-9]+$", "Only numbers allowed").trim().required(),
                service1: Yup.string().min(5).max(50).required(),
                service2: Yup.string().min(5).max(50),
                service3: Yup.string().min(5).max(50),
                service4: Yup.string().min(5).max(50),
                whatsapp: Yup.string().matches("[0-9]+$", "Only numbers allowed").min(5).max(50),
                phone: Yup.string().matches("[0-9]+$", "Only numbers allowed").min(5).max(50).required(),
                email: Yup.string().email(),
                website: Yup.string().min(5).max(50),
                address: Yup.string().min(5).max(50),
                twitter: Yup.string().min(5).max(50),
                instagram: Yup.string().min(5).max(50),
                location: Yup.string().min(5).max(50).required(),
              })}
              onSubmit={(values, { setSubmitting }) => {
                values["alumnusId"] = alumnus.id; 
                // put values that changed into the tosend object
  
                Promise.resolve(addBusiness(values))
                  .then(function (result) {
                    if (result.status === true) {
                      
                      setBusinessAddState('success');
                      setSubmitting(false);
                    } else if (result.status === false) {
                      setErrorMsg({
                        status: true,
                        message: result.message,
                        code: result?.errorCode,
                      });
                      setSubmitting(false);
                    }
                  })
                  .catch((error) => {});
              }}
            >
              {({ isSubmitting }) => (
                <Form className="form">
                  <div className="profile-edit__detail">
                    <h3 className="title">Professional Info</h3>
                    {/* Name */}
                    <div>
                      <div className="form-group">
                        <label className="form-group__label" htmlFor="name">
                          Business name
                        </label>
                        <Field
                          className="form-group__input text-base"
                          name="name"
                          type="text"
                        />
                      </div>
  
                      <ErrorMessage
                        render={(msg) => (
                          <div className="form-group__error-message">{msg}</div>
                        )}
                        name="name"
                      />
                    </div>
  
                    {/* description */}
                    <div>
                      <div className="form-group">
                        <label
                          className="form-group__label"
                          htmlFor="description"
                        >
                          Description (About your business)
                        </label>
                        <Field
                          className="form-group__input"
                          name="description"
                          type="textarea"
                          rows="20"
                        />
                      </div>
                      <ErrorMessage
                        render={(msg) => (
                          <div className="form-group__error-message">{msg}</div>
                        )}
                        name="description"
                      />
                    </div>
  
                    {/* Industry */}
                    <div>
                      <div className="form-group">
                        <label className="form-group__label" htmlFor="industry">
                          What industry do you work in
                        </label>
                        <Field
                          className="form-group__input"
                          name="industry"
                          as="select"
                        >
                          {industryList.map((industry, i) => {
                            return (
                              <option value={industry} key={i}>
                                {industry}
                              </option>
                            );
                          })}
                        </Field>
                      </div>
                      <ErrorMessage
                        render={(msg) => (
                          <div className="form-group__error-message">{msg}</div>
                        )}
                        name="industry"
                      />
                    </div>
  
                    {/* EmployeeNumber */}
                    <div>
                      <div className="form-group">
                        <label
                          className="form-group__label"
                          htmlFor="employeeNumber"
                        >
                          How many employees do you have ?
                        </label>
                        <Field
                          className="form-group__input"
                          name="employeeNumber"
                          type="text"
                        />
                      </div>
                      <ErrorMessage
                        render={(msg) => (
                          <div className="form-group__error-message">{msg}</div>
                        )}
                        name="employeeNumber"
                      />
                    </div>
  
                    {/* Service 1 */}
                    <div>
                      <div className="form-group">
                        <label className="form-group__label" htmlFor="service1">
                          Service 1 (e.g logistics)
                        </label>
                        <Field
                          className="form-group__input"
                          name="service1"
                          type="text"
                        />
                      </div>
                      <ErrorMessage
                        render={(msg) => (
                          <div className="form-group__error-message">{msg}</div>
                        )}
                        name="service1"
                      />
                    </div>
  
                    {/* Service 2 */}
                    <div>
                      <div className="form-group">
                        <label className="form-group__label" htmlFor="service2">
                          Service 2
                        </label>
                        <Field
                          className="form-group__input"
                          name="service2"
                          type="text"
                        />
                      </div>
                      <ErrorMessage
                        render={(msg) => (
                          <div className="form-group__error-message">{msg}</div>
                        )}
                        name="service2"
                      />
                    </div>
  
                    {/* Service 3 */}
                    <div>
                      <div className="form-group">
                        <label className="form-group__label" htmlFor="service3">
                          Service 3
                        </label>
                        <Field
                          className="form-group__input"
                          name="service3"
                          type="text"
                        />
                      </div>
                      <ErrorMessage
                        render={(msg) => (
                          <div className="form-group__error-message">{msg}</div>
                        )}
                        name="service3"
                      />
                    </div>
  
                    {/* Service 4 */}
                    <div>
                      <div className="form-group">
                        <label className="form-group__label" htmlFor="service4">
                          Service 4
                        </label>
                        <Field
                          className="form-group__input"
                          name="service4"
                          type="text"
                        />
                      </div>
                      <ErrorMessage
                        render={(msg) => (
                          <div className="form-group__error-message">{msg}</div>
                        )}
                        name="service4"
                      />
                    </div>
                  </div>
  
                  <div className="profile-edit__detail">
                    <h3 className="title">Contact</h3>
  
                    {/* Whatsapp */}
                    <div>
                      <div className="form-group">
                        <label className="form-group__label" htmlFor="whatsapp">
                          Whatsapp line
                        </label>
                        <Field
                          className="form-group__input"
                          name="whatsapp"
                          type="text"
                        />
                      </div>
                      <ErrorMessage
                        render={(msg) => (
                          <div className="form-group__error-message">{msg}</div>
                        )}
                        name="whatsapp"
                      />
                    </div>
  
                    {/* Phone */}
                    <div>
                      <div className="form-group">
                        <label className="form-group__label" htmlFor="phone">
                          Phone
                        </label>
                        <Field
                          className="form-group__input"
                          name="phone"
                          type="text"
                        />
                      </div>
                      <ErrorMessage
                        render={(msg) => (
                          <div className="form-group__error-message">{msg}</div>
                        )}
                        name="phone"
                      />
                    </div>
  
                    {/* Email */}
                    <div>
                      <div className="form-group">
                        <label className="form-group__label" htmlFor="email">
                          Email
                        </label>
                        <Field
                          className="form-group__input"
                          name="email"
                          type="text"
                        />
                      </div>
                      <ErrorMessage
                        render={(msg) => (
                          <div className="form-group__error-message">{msg}</div>
                        )}
                        name="email"
                      />
                    </div>
  
                    {/* Website */}
                    <div>
                      <div className="form-group">
                        <label className="form-group__label" htmlFor="website">
                          Website
                        </label>
                        <Field
                          className="form-group__input"
                          name="website"
                          type="text"
                        />
                      </div>
                      <ErrorMessage
                        render={(msg) => (
                          <div className="form-group__error-message">{msg}</div>
                        )}
                        name="website"
                      />
                    </div>
  
                    {/* Location */}
                    <div>
                      <div className="form-group">
                        <label className="form-group__label" htmlFor="location">
                          Location (City)
                        </label>
                        <Field
                          className="form-group__input"
                          name="location"
                          type="text"
                        />
                      </div>
                      <ErrorMessage
                        render={(msg) => (
                          <div className="form-group__error-message">{msg}</div>
                        )}
                        name="location"
                      />
                    </div>
  
                    {/* Address */}
                    <div>
                      <div className="form-group">
                        <label className="form-group__label" htmlFor="address">
                          Business Address
                        </label>
                        <Field
                          className="form-group__input"
                          name="address"
                          type="text"
                        />
                      </div>
                      <ErrorMessage
                        render={(msg) => (
                          <div className="form-group__error-message">{msg}</div>
                        )}
                        name="address"
                      />
                    </div>
                  </div>
  
                  <div className="profile-edit__detail">
                    <h3 className="title">Social media</h3>
                    {/* Twitter */}
                    <div>
                      <div className="form-group">
                        <label className="form-group__label" htmlFor="twitter">
                          Twitter
                        </label>
                        <Field
                          className="form-group__input"
                          name="twitter"
                          type="text"
                        />
                      </div>
                      <ErrorMessage
                        render={(msg) => (
                          <div className="form-group__error-message">{msg}</div>
                        )}
                        name="twitter"
                      />
                    </div>
  
                    {/* Instagram */}
                    <div>
                      <div className="form-group">
                        <label className="form-group__label" htmlFor="instagram">
                          Instagram
                        </label>
                        <Field
                          className="form-group__input"
                          name="instagram"
                          type="text"
                        />
                      </div>
                      <ErrorMessage
                        render={(msg) => (
                          <div className="form-group__error-message">{msg}</div>
                        )}
                        name="instagram"
                      />
                    </div>
                  </div>
  
                  <FormMessage state={errorMsg} setState={setErrorMsg} />
  
                  <button
                    className="flex justify-center text-sm bg-primary items-center transition duration-300 focus:bg-primary  hover:bg-primary-light text-center text-white font-medium py-2 px-4  rounded-md focus:outline-none"
                    type="submit"
                    // disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <span className="text-lg flex items-center space-x-1">
                        <BiLoader />
                        <p className="text-sm">Loading</p>
                      </span>
                    ) : (
                      "Update profile"
                    )}
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    );
  }
  else if(businessAddState === 'success'){
    return(
      <div>
      <div className=" min-h-full px-4 py-4 space-y-4">

        <div className="flex flex-col">
          <span className="flex justify-center text-6xl text-primary-dark"><FaEnvelopeOpenText/></span>
          <h2 className=" text-center mt-2 text-base text-primary-dark font-bold">Thanks!! you will get an email once your business has been verified</h2> 
        </div>

        <div>
          <p className="text-sm text-center text-gray-700">You will be able to view and edit your business details once it has been verified </p>
        </div>

        <div>
          <Link to="/businesses"  className="w-full mt-3 flex justify-center focus:bg-primary-dark hover:bg-primary hover:text-white text-sm rounded px-4 py-3 items-center duration-200 bg-primary-dark text-white">Close</Link>
        </div>
      </div>
  </div>
    )
  }
};

export default BusinessAdd;
