const industryList = [
  'Accounting and Tax Services',
  'Arts, Culture and Entertainment',
	'Auto Sales and Service',
	'Banking and Finance',
	'Business Services',
	'Community Organizations',
  'Education',
  'Fashion',
  'Financial Services',
	'Health Care',
	'Health and Wellness',
	'Home Improvement',
  'Hospitality',
	'Internet and Web Services',
	'Legal Services',
  'Marketing and Advertising',
	'News and Media',
  'Pet Services',
	'Real Estate',
  'Restaurants and Nightlife',
  'Shopping and Retail',
	'Sports and Recreation',
	'Travel and Lodging',
	'Transportation',
	'Utilities',
]

export default industryList;

