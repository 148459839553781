import Nav from "../components/Nav";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { BiLoader } from "react-icons/bi";
import { MdClose } from "react-icons/md";
import "../styles/profileEditPage.css";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import industryList from "../data/job-industries";
import { populateBusiness } from "../redux/business";
import { useHistory } from "react-router-dom";
import { getCourses, updateBusiness } from "../services/fetchRequests";
import degrees from "../data/degrees";
import RenderImage from "../components/ProfileImageUpload/renderImage/RenderImage";

// component that displays message when form is submitted
function FormMessage(props) {
  const state = { ...props.state };
  const setState = props.setState;
  // const dispatch = useDispatch();

  return (
    <div
      className={`form-message border border-red-500 rounded-lg bg-white p-4 ${
        state.status ? "" : "hidden"
      }`}
    >
      {/* title and close button */}
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-1">
          <span className="text-base text-red-600">
            <AiOutlineExclamationCircle />
          </span>
          <p className="text-sm text-red-700 font-semibold">
            Something went wrong
          </p>
        </div>

        <span
          onClick={(e) => setState({ status: false })}
          className="form-message-close justify-self-end cursor-pointer"
        >
          <MdClose />
        </span>
      </div>

      {/* Error */}
      {/* {state.code ? <p className="text-xs pl-2 pt-2  text-gray-600">{state.code}</p> : ''} */}

      {/* message */}
      {state.message ? (
        <p className="text-xs pl-2 pt-2  text-gray-600">{state.message}</p>
      ) : (
        ""
      )}

      {state.code && state.code === "V-300" ? (
        <p className="text-xs pl-2 pt-2  text-gray-600">what fields</p>
      ) : (
        ""
      )}

      {state.code === "unknown" ? (
        <p className="text-xs pl-2 pt-2  text-gray-600">
          An unknown error occured.{" "}
          <a
            href={`https://wa.me/2349030009002?text=Hi%2C%20%0A%0AI%20am%20experiencing%20technical%20while%20trying%20to%20create%20an%20ABUAD%20alumni%20account`}
            alt="help"
          >
            Click here
          </a>{" "}
          to contact help{" "}
        </p>
      ) : (
        ""
      )}
    </div>
  );
}

const BusinessEdit = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  // go to login page if not logged in
  // props.loggedIn.value === false ? <Redirect to="/" /> : "";

  // get profile array from redux
  const business = useSelector((state) => state.business);
  const [errorMsg, setErrorMsg] = useState({
    status: false,
    message: false,
    code: false,
  });
  const [image, setDbImage] = useState(business.image ?? null);

  // Get and populate all courses
  useEffect(() => {
    // get the courses from the api
    Promise.resolve(getCourses())
      .then((result) => {
        // create empty arrays to categorize courses by degree level
        const bscCourseArray = [];
        const mscCourseArray = [];

        // loop through courses and push them into their arrays
        result.forEach((element) => {
          const degreeId = element.degreeId;
          switch (degrees[degreeId].level) {
            case 1:
              bscCourseArray.push(element);
              break;
            case 2:
              mscCourseArray.push(element);
              break;
            default:
            // code block
          }
        });
      })
      .catch((error) => {});
  }, []);

  return (
    <div className="bg-gray-100">
      <Nav />
      <div className="profile-edit">
        <div className="h-96 bg-white mb-6 flex justify-center pb-7 pt-20  md:mx-auto md:w-96">
          <RenderImage image={image} setDbImage={setDbImage} />
        </div>
        <div className="profile-edit__details">
          <Formik
            initialValues={{
              id: business.id,
              registrationNumber: business.registrationNumber
                ? business.registrationNumber
                : "",
              employeeNumber: business.employeeNumber
                ? business.employeeNumber
                : "",
              name: business.name ? business.name : "",
              description: business.description ? business.description : "",
              industry: business.industry ? business.industry : "",
              service1: business.service1 ? business.service1 : "",
              service2: business.service1 ? business.service2 : "",
              service3: business.service1 ? business.service3 : "",
              service4: business.service4 ? business.service4 : "",
              whatsapp: business.whatsapp ? business.whatsapp : "",
              phone: business.phone ? business.phone : "",
              email: business.email ? business.email : "",
              twitter: business.twitter ? business.twitter : "",
              instagram: business.instagram ? business.instagram : "",
              image: business.image ? business.image : "",
              website: business.website ? business.website : "",
              address: business.address ? business.address : "",
              location: business.location ? business.location : "",
              createdAt: business.createdAt ? business.createdAt : "",
              lastUpdate: business.lastUpdate ? business.lastUpdate : "",
            }}
            validationSchema={Yup.object({
              name: Yup.string().trim().min(2).max(30).required(),
              description: Yup.string().trim().min(2).max(300).required(),
              industry: Yup.string().required(),
              employeeNumber: Yup.string().matches("[0-9]+$", "Only numbers allowed").trim().required(),
              service1: Yup.string().min(5).max(50).required(),
              service2: Yup.string().min(5).max(50),
              service3: Yup.string().min(5).max(50),
              service4: Yup.string().min(5).max(50),
              whatsapp: Yup.string().matches("[0-9]+$", "Only numbers allowed").min(5).max(50),
              phone: Yup.string().matches("[0-9]+$", "Only numbers allowed").min(5).max(50).required(),
              email: Yup.string().email(),
              website: Yup.string().min(5).max(50),
              address: Yup.string().min(5).max(100),
              twitter: Yup.string().min(5).max(50),
              instagram: Yup.string().min(5).max(50),
              location: Yup.string().min(5).max(50).required(),
            })}
            onSubmit={(values, { setSubmitting }) => {
              const toSend = { id: business.id};
              // put values that changed into the tosend object
              for (const value in values) {
                // if the value is not exact as in the profile and it's not an empty string
                if (values[value] !== business[value] && values[value] !== "") {
                  toSend[value] = values[value];
                }
              }
              
              // test if the image is a base64...if not, don't add to the request
              const regex = new RegExp('^data:image/jpeg;base64', 'i')
              if(regex.test(image)){
                toSend['image'] = image
              }
              Promise.resolve(updateBusiness(toSend))
                .then(function (result) {
                  if (result.status === true) {
                    
                    dispatch(populateBusiness(result.data));
                    setSubmitting(false);
                    history.push("/businesses");
                  } else if (result.status === false) {
                    setErrorMsg({
                      status: true,
                      message: result.message,
                      code: result?.errorCode,
                    });
                    setSubmitting(false);
                  }
                })
                .catch((error) => {});
            }}
          >
            {({ isSubmitting }) => (
              <Form className="form">
                <div className="profile-edit__detail">
                  <h3 className="title">Professional Info</h3>
                  {/* Name */}
                  <div>
                    <div className="form-group">
                      <label className="form-group__label" htmlFor="name">
                        Business name
                      </label>
                      <Field
                        className="form-group__input text-base"
                        name="name"
                        type="text"
                        placeholder={business.name ? business.name : "-"}
                      />
                    </div>

                    <ErrorMessage
                      render={(msg) => (
                        <div className="form-group__error-message">{msg}</div>
                      )}
                      name="name"
                    />
                  </div>

                  {/* description */}
                  <div>
                    <div className="form-group">
                      <label
                        className="form-group__label"
                        htmlFor="description"
                      >
                        Description (About your business)
                      </label>
                      <Field
                        className="form-group__input"
                        name="description"
                        type="textarea"
                        rows="20"
                        placeholder={
                          business.description ? business.description : "-"
                        }
                      />
                    </div>
                    <ErrorMessage
                      render={(msg) => (
                        <div className="form-group__error-message">{msg}</div>
                      )}
                      name="description"
                    />
                  </div>

                  {/* Industry */}
                  <div>
                    <div className="form-group">
                      <label className="form-group__label" htmlFor="industry">
                        What industry do you work in
                      </label>
                      <Field
                        className="form-group__input"
                        name="industry"
                        as="select"
                      >
                        {business.industry ? (
                          <option value={business.industry}>
                            {business.industry}
                          </option>
                        ) : (
                          <option value="">-</option>
                        )}
                        {industryList.map((industry, i) => {
                          return business.industry === industry ? (
                            ""
                          ) : (
                            <option value={industry} key={i}>
                              {industry}
                            </option>
                          );
                        })}
                      </Field>
                    </div>
                    <ErrorMessage
                      render={(msg) => (
                        <div className="form-group__error-message">{msg}</div>
                      )}
                      name="industry"
                    />
                  </div>

                  {/* EmployeeNumber */}
                  <div>
                    <div className="form-group">
                      <label
                        className="form-group__label"
                        htmlFor="employeeNumber"
                      >
                        How many employees do you have ?
                      </label>
                      <Field
                        className="form-group__input"
                        name="employeeNumber"
                        type="text"
                        placeholder={
                          business.employeeNumber
                            ? business.employeeNumber
                            : "-"
                        }
                      />
                    </div>
                    <ErrorMessage
                      render={(msg) => (
                        <div className="form-group__error-message">{msg}</div>
                      )}
                      name="employeeNumber"
                    />
                  </div>

                  {/* Service 1 */}
                  <div>
                    <div className="form-group">
                      <label className="form-group__label" htmlFor="service1">
                        Service 1 (e.g logistics)
                      </label>
                      <Field
                        className="form-group__input"
                        name="service1"
                        type="text"
                        placeholder={
                          business.service1 ? business.service1 : "-"
                        }
                      />
                    </div>
                    <ErrorMessage
                      render={(msg) => (
                        <div className="form-group__error-message">{msg}</div>
                      )}
                      name="service1"
                    />
                  </div>

                  {/* Service 2 */}
                  <div>
                    <div className="form-group">
                      <label className="form-group__label" htmlFor="service2">
                        Service 2
                      </label>
                      <Field
                        className="form-group__input"
                        name="service2"
                        type="text"
                        placeholder={
                          business.service2 ? business.service2 : "-"
                        }
                      />
                    </div>
                    <ErrorMessage
                      render={(msg) => (
                        <div className="form-group__error-message">{msg}</div>
                      )}
                      name="service2"
                    />
                  </div>

                  {/* Service 3 */}
                  <div>
                    <div className="form-group">
                      <label className="form-group__label" htmlFor="service3">
                        Service 3
                      </label>
                      <Field
                        className="form-group__input"
                        name="service3"
                        type="text"
                        placeholder={
                          business.service3 ? business.service3 : "-"
                        }
                      />
                    </div>
                    <ErrorMessage
                      render={(msg) => (
                        <div className="form-group__error-message">{msg}</div>
                      )}
                      name="service3"
                    />
                  </div>

                  {/* Service 4 */}
                  <div>
                    <div className="form-group">
                      <label className="form-group__label" htmlFor="service4">
                        Service 4
                      </label>
                      <Field
                        className="form-group__input"
                        name="service4"
                        type="text"
                        placeholder={
                          business.service4 ? business.service4 : "-"
                        }
                      />
                    </div>
                    <ErrorMessage
                      render={(msg) => (
                        <div className="form-group__error-message">{msg}</div>
                      )}
                      name="service4"
                    />
                  </div>
                </div>

                <div className="profile-edit__detail">
                  <h3 className="title">Contact</h3>

                  {/* Whatsapp */}
                  <div>
                    <div className="form-group">
                      <label className="form-group__label" htmlFor="whatsapp">
                        Whatsapp line
                      </label>
                      <Field
                        className="form-group__input"
                        name="whatsapp"
                        type="text"
                        placeholder={
                          business.whatsapp ? business.whatsapp : "-"
                        }
                      />
                    </div>
                    <ErrorMessage
                      render={(msg) => (
                        <div className="form-group__error-message">{msg}</div>
                      )}
                      name="whatsapp"
                    />
                  </div>

                  {/* Phone */}
                  <div>
                    <div className="form-group">
                      <label className="form-group__label" htmlFor="phone">
                        Phone
                      </label>
                      <Field
                        className="form-group__input"
                        name="phone"
                        type="text"
                        placeholder={business.phone ? business.phone : "-"}
                      />
                    </div>
                    <ErrorMessage
                      render={(msg) => (
                        <div className="form-group__error-message">{msg}</div>
                      )}
                      name="phone"
                    />
                  </div>

                  {/* Email */}
                  <div>
                    <div className="form-group">
                      <label className="form-group__label" htmlFor="email">
                        Email
                      </label>
                      <Field
                        className="form-group__input"
                        name="email"
                        type="text"
                        placeholder={business.email ? business.email : "-"}
                      />
                    </div>
                    <ErrorMessage
                      render={(msg) => (
                        <div className="form-group__error-message">{msg}</div>
                      )}
                      name="email"
                    />
                  </div>

                  {/* Website */}
                  <div>
                    <div className="form-group">
                      <label className="form-group__label" htmlFor="website">
                        Website
                      </label>
                      <Field
                        className="form-group__input"
                        name="website"
                        type="text"
                        placeholder={business.website ? business.website : "-"}
                      />
                    </div>
                    <ErrorMessage
                      render={(msg) => (
                        <div className="form-group__error-message">{msg}</div>
                      )}
                      name="website"
                    />
                  </div>

                  {/* Location */}
                  <div>
                    <div className="form-group">
                      <label className="form-group__label" htmlFor="location">
                        Location (City)
                      </label>
                      <Field
                        className="form-group__input"
                        name="location"
                        type="text"
                        placeholder={
                          business.location ? business.location : "-"
                        }
                      />
                    </div>
                    <ErrorMessage
                      render={(msg) => (
                        <div className="form-group__error-message">{msg}</div>
                      )}
                      name="location"
                    />
                  </div>

                  {/* Address */}
                  <div>
                    <div className="form-group">
                      <label className="form-group__label" htmlFor="address">
                        Business Address
                      </label>
                      <Field
                        className="form-group__input"
                        name="address"
                        type="text"
                        placeholder={business.address ? business.address : "-"}
                      />
                    </div>
                    <ErrorMessage
                      render={(msg) => (
                        <div className="form-group__error-message">{msg}</div>
                      )}
                      name="address"
                    />
                  </div>
                </div>

                <div className="profile-edit__detail">
                  <h3 className="title">Social media</h3>
                  {/* Twitter */}
                  <div>
                    <div className="form-group">
                      <label className="form-group__label" htmlFor="twitter">
                        Twitter
                      </label>
                      <Field
                        className="form-group__input"
                        name="twitter"
                        type="text"
                        disabled={true}
                        placeholder={business.twitter ? business.twitter : "-"}
                      />
                    </div>
                    <ErrorMessage
                      render={(msg) => (
                        <div className="form-group__error-message">{msg}</div>
                      )}
                      name="twitter"
                    />
                  </div>

                  {/* Instagram */}
                  <div>
                    <div className="form-group">
                      <label className="form-group__label" htmlFor="instagram">
                        Instagram
                      </label>
                      <Field
                        className="form-group__input"
                        name="instagram"
                        type="text"
                        disabled={true}
                        placeholder={
                          business.instagram ? business.instagram : "-"
                        }
                      />
                    </div>
                    <ErrorMessage
                      render={(msg) => (
                        <div className="form-group__error-message">{msg}</div>
                      )}
                      name="instagram"
                    />
                  </div>
                </div>

                <FormMessage state={errorMsg} setState={setErrorMsg} />

                <button
                  className="flex justify-center text-sm bg-primary items-center transition duration-300 focus:bg-primary  hover:bg-primary-light text-center text-white font-medium py-2 px-4  rounded-md focus:outline-none"
                  type="submit"
                  // disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <span className="text-lg flex items-center space-x-1">
                      <BiLoader />
                      <p className="text-sm">Loading</p>
                    </span>
                  ) : (
                    "Update profile"
                  )}
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default BusinessEdit;
