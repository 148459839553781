import '../styles/Nav.css';
import {FaChevronLeft} from 'react-icons/fa';
import {useHistory} from 'react-router-dom';
import { FiMenu } from "react-icons/fi";
import { useEffect } from 'react';
import {Link, matchPath} from 'react-router-dom';
import logo from "../img/abuad-alumni-logo.png";

function toggleDropDown(e) {
  document.getElementById("myDropdown").classList.toggle("show");
}

// function to get the closest element with a particular class
/**
 * 
 * @param {*} elem the element
 * @param {*} selector the selector of the parent element
 * @returns 
 */
const getClosest = function (elem, selector) {

	// Element.matches() polyfill
	if (!Element.prototype.matches) {
	    Element.prototype.matches =
	        Element.prototype.matchesSelector ||
	        Element.prototype.mozMatchesSelector ||
	        Element.prototype.msMatchesSelector ||
	        Element.prototype.oMatchesSelector ||
	        Element.prototype.webkitMatchesSelector ||
	        function(s) {
	            var matches = (this.document || this.ownerDocument).querySelectorAll(s),
	                i = matches.length;
	            while (--i >= 0 && matches.item(i) !== this) {}
	            return i > -1;
	        };
	}

	// Get the closest matching element
	for ( ; elem && elem !== document; elem = elem.parentNode ) {
		if ( elem.matches( selector ) ) return elem;
	}
	return null;

};

const Nav = () => {
  const history = useHistory();

  useEffect( ()=> {
    // Close the dropdown if the user clicks outside of it
    window.onclick = function(event) {
      // if the target is not the dropdown button and the parent of the target is not the dropdown button
      if (!event.target.matches('.dropbtn') && getClosest(event.target, '.dropbtn') === null ) {
        var dropdowns = document.getElementsByClassName("nav__menu-content");
        var i;
        for (i = 0; i < dropdowns.length; i++) {
          var openDropdown = dropdowns[i];
          if (openDropdown.classList.contains('show')) {
            openDropdown.classList.remove('show');
          }
        }
      }
    }

  },[]);

  // check the current path name to determine what the nav title will be
  let title;
  switch(history.location.pathname){
    case'/profile':
      title = 'Profile';
      break;
    case'/profile/edit':
      title = 'Edit profile';
      break;
    case'/directory':
      title = 'Directory';
      break;
    case'/directory/alumnus':
      title = 'Edit profile';
      break;
    default:
      title = "";
  }

  //determine if the back button should show
  let backButton;
  switch(history.location.pathname){
    case'/profile':
      backButton = true;
      break;
    case'/directory':
      backButton = false;
      break;
    case'/directory/search':
      backButton = false;
      break;
    default:
      backButton = true;
  }

  // check if a path matches a string
  function checkPath(path){
    return matchPath(history.location.pathname, path)?.isExact
  }

  return (
    <div className="flex fixed w-full flex-row items-center bg-white md:space-x-5 z-50">
      {/* hide the back button if the value of back button is false */}
      {backButton ? <span onClick={() => history.goBack()} className="nav__direction"><FaChevronLeft/></span> : <div className="py-3 flex space-x-2 w-full items-center"><img src={logo} className="h-7 pl-4" alt="Abuad alumni logo"></img> <p className="text-base font-bold text-primary-dark">Abuad Alumni</p></div> }

      <p className="nav__direction-name">{title}</p>
      <div className="nav__menu">
        <div className="dropbtn" onClick={(e) => toggleDropDown()}>
          <FiMenu/>
        </div>
        
        <div id="myDropdown" className="nav__menu-content">
          {checkPath('/menu') ? '' : <Link to="/menu">Home</Link>}
          {checkPath('/profile') ? '' : <Link to="/profile">Profile</Link> }
          {checkPath('/directory') ? '' : <Link to="/directory">Alumni directory</Link>}
          {checkPath('/businesses') ? '' : <Link to="/businesses">Business directory</Link>}
          <Link to="/" className="text-red-700">Logout</Link>
        </div>
      </div>
    </div>
  );
}

export default Nav;
