const degrees = {
  1: {
    name: "B.Sc",
    level: 1,
  },
  2: {
    name: "B.Arch",
    level: 1,
  },
  3: {
    name: "Ph.D",
    level: 3,
  },
  4: {
    name: "M.Sc",
    level: 2,
  },
  5: {
    name: "LLM",
    level: 2,
  },
  6: {
    name: "PGD",
    level: 2,
  },
  7: {
    name: "M.Eng",
    level: 2,
  },
  8: {
    name: "MBA",
    level: 2,
  },
  9: {
    name: "MBA Professional",
    level: 2,
  },
  10: {
    name: "M.Sc Professional",
    level: 2,
  },
  11: {
    name: "MILR",
    level: 1,
  },
  13: {
    name: "B.A",
    level: 1,
  },
  14: {
    name: "B.Agric",
    level: 1,
  },
  15: {
    name: "B.Eng",
    level: 1,
  },
  16: {
    name: "M.B.B.S",
    level: 2,
  },
  17: {
    name: "B.Nsc",
    level: 1,
  },
  18: {
    name: "B.MLS",
    level: 1,
  },
  19: {
    name: "Pharm.D",
    level: 1,
  },
  20: {
    name: "B.DS",
    level: 1,
  },
  21: {
    name: "OD",
    level: 1,
  },
  22: {
    name: "LLB",
    level: 1,
  },
};

export default degrees;
