import '../styles/search.css';
import {Formik, Form, Field } from 'formik';
import Nav from './Nav';
import AppBarBottom from './AppBarBottom';
import { useState, useEffect,} from 'react';
import {BiSearchAlt} from 'react-icons/bi';
import AlumniSearchResult from './AlumniSearchResult';
import { useLocation } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { getAlumnusByName } from '../services/fetchRequests';

// function to get the closest element with a particular class
/**
 * 
 * @param {*} elem the element
 * @param {*} selector the selector of the parent element
 * @returns 
 */
 const getClosest = function (elem, selector) {

	// Element.matches() polyfill
	if (!Element.prototype.matches) {
	    Element.prototype.matches =
	        Element.prototype.matchesSelector ||
	        Element.prototype.mozMatchesSelector ||
	        Element.prototype.msMatchesSelector ||
	        Element.prototype.oMatchesSelector ||
	        Element.prototype.webkitMatchesSelector ||
	        function(s) {
	            var matches = (this.document || this.ownerDocument).querySelectorAll(s),
	                i = matches.length;
	            while (--i >= 0 && matches.item(i) !== this) {}
	            return i > -1;
	        };
	}

	// Get the closest matching element
	for ( ; elem && elem !== document; elem = elem.parentNode ) {
		if ( elem.matches( selector ) ) return elem;
	}
	return null;

};

const Search = (props) => {
  const { pathname } = useLocation();
  // false, searching & idle
  const [searchResult, setSearchResult] = useState({status: 'idle'});

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect( ()=> {
    // Close the dropdown if the user clicks outside of it
    window.onclick = function(event) {
      // if the target is not the dropdown button and the parent of the target is not the dropdown button
      if (!event.target.matches('.filterDropdown') && getClosest(event.target, '.searchBar') === null ) {
        // var dropdowns = document.getElementsByClassName("nav__menu-content");
        var dropDown = document.getElementById("filterDropdown");
        dropDown?.classList.add('hidden')
        
      }
    }

  },[]);

  // go to login page if not logged in
  if(props.loggedIn === false){
    return <Redirect to='/'/>
  }

  return (
    <div>
      <Nav/>
      <div className="search pt-20  bg-gray-100">   

        <div className="searchBar relative max-w-md mx-auto">
          <Formik
            initialValues={{
              fullName: '',
            }}
            onSubmit={ (values) =>{
              setSearchResult({status: 'searching'})
              
              Promise.resolve(getAlumnusByName(values.fullName))
              .then((result)=>{
                console.log(result);
                setSearchResult(result)
              })
            }}
          >
            <Form>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <label className="block text-xs font-medium text-gray-400 mb-2 md:font-semibold md:text-sm sr-only" htmlFor="fullName">First name</label>
                  <Field className="appearance-none shadow-none border border-gray-200 rounded w-full py-2 pl-8 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-primary" name="fullName" type="text" placeholder="Search" />
                  <span className="absolute left-0 top-0 mt-3 ml-3"> <BiSearchAlt/></span>
                </div>
            </Form>
          </Formik>
        </div>


        <div className="pt-6 pb-20">
          <AlumniSearchResult result={searchResult}/>
        </div>

      </div>
      <AppBarBottom/>
    </div>
  );
}

export default Search;
