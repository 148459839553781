import { createSlice } from "@reduxjs/toolkit";

export const businessSlice = createSlice({
  name: "business",
  initialState: {
    status: "",
    id: "",
    alumnusId: "",
    registrationNumber: "",
    employeeNumber: "",
    name: "",
    description: "",
    industry: "",
    service1: "",
    service2: "",
    service3: "",
    service4: "",
    location: "",
    address: "",
    whatsapp: "",
    phone: "",
    email: "",
    twitter: "",
    instagram: "",
    website: "",
    createdAt: "",
    lastUpdate: "",
    visible: "",
    image: "",
  },
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1;
    },
    decrement: (state) => {
      state.value -= 1;
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload;
    },
    populateBusiness: (state, action) => {
      for (const i in state) {
        state[i] = action.payload[i];
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { increment, decrement, incrementByAmount, populateBusiness } =
  businessSlice.actions;

export default businessSlice.reducer;
