export function hideemail(target) {
  var email = target //anas.behhari@gmail.com
  var hiddenEmail = "";
  var i;
  for (i = 0; i < email.length; i++) {
    if (i > 2 && i< email.indexOf("@") ) {
      hiddenEmail += "*";
    } else {
      hiddenEmail += email[i];
    }
  }
  return hiddenEmail;
}

export function hideText(target) {
  var text = target //anas.behhari@gmail.com
  var hiddenText = "";
  var i;
  for (i = 0; i < text.length; i++) {
    if (i > 2 && i< (text.length - 4) ) {
      hiddenText += "*";
    } else {
      hiddenText += text[i];
    }
  }
  return hiddenText;
}