export async function getAlumniList(request) {
  try {
    const res = await fetch(
      `https://hego3rip.com/api/abuad-alumni/alumni?firstName=%${request.search}%`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: process.env.REACT_APP_ABUAD_API_USER_KEY,
        },
      }
    );
    const courses = await res.json();

    // return courses array if the response status is true
    // else throw an error
    if (courses?.status === true) {
      return courses.data;
    } else {
      return [];
    }
  } catch (err) {
    return [];
  }
}

// get an alumnus from the api
export async function getAlumniFilter(validFields) {
  // function to join values together to make an API request
  function generateRequest(values) {
    let str = "?";
    for (let i in values) {
      if (values[i].trim().length > 0) {
        str += `${i}=%${values[i]}%&`;
      }
    }
    //get the values that are available
    return str;
  }

  try {
    const res = await fetch(
      `https://hego3rip.com/api/abuad-alumni/alumni${generateRequest(
        validFields
      )}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: process.env.REACT_APP_ABUAD_API_USER_KEY,
        },
      }
    );
    const courses = await res.json();

    // return courses array if the response status is true
    // else throw an error
    if (courses?.status === true) {
      return courses.data;
    } else {
      return [];
    }
  } catch (err) {
    return [];
  }
}

export async function findAccount(
  email = "",
  password = "",
  key = process.env.REACT_APP_ABUAD_API_ADMIN_KEY
) {
  function extractPassword(alumni) {
    var str = alumni.dateOfBirth;
    return `${alumni.id}${str.slice(4)}`;
  }

  try {
    const res = await fetch(
      `${process.env.REACT_APP_ALUMNI_API}/alumni?email=${email}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: key,
        },
      }
    );
    const response = await res.json();

    if (response.status === true) {
      // if password is correct and the alumni is visible
      if (
        password === extractPassword(response.data[0]) &&
        response.data[0].visible === "true"
      ) {
        return {
          status: true,
          alumnus: response.data[0],
        };
      }
      // if password is correct but visibility is false
      else if (
        password === extractPassword(response.data[0]) &&
        response.data[0].visible === "false"
      ) {
        return {
          status: false,
          message: "Your account is pending verification",
        };
      } else if (password !== extractPassword(response.data[0])) {
        return {
          status: false,
          message: "username or password incorrect",
        };
      }
    } else {
      return {
        status: false,
        message: "User not found",
      };
    }
  } catch (err) {
    return {
      status: false,
      code: "wrong",
    };
  }
}

export async function getCourses() {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_ALUMNI_API}/course?perPage=200`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: process.env.REACT_APP_ABUAD_API_USER_KEY,
        },
      }
    );
    const courses = await res.json();

    // return courses array if the response status is true
    // else throw an error
    if (courses?.status === true) {
      return courses.data;
    } else {
      throw new Error("something happened");
    }
  } catch (err) {
    return false;
  }
}

export async function updateAlumnus(validFields) {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_ALUMNI_API}/alumni-update`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: process.env.REACT_APP_ABUAD_API_USER_KEY,
        },
        body: JSON.stringify(validFields),
      }
    );
    const response = await res.json();
    return response;
  } catch (err) {
    return {
      status: false,
      message: "something went wrong. please try again or contact support",
    };
  }
}

export async function addBusiness(validFields) {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_ALUMNI_API}/business`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: process.env.REACT_APP_ABUAD_API_USER_KEY,
        },
        body: JSON.stringify(validFields),
      }
    );
    const response = await res.json();
    return response;
  } catch (err) {
    return {
      status: false,
      message: "something went wrong. please try again or contact support",
    };
  }
}

export async function updateBusiness(validFields) {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_ALUMNI_API}/business-update`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: process.env.REACT_APP_ABUAD_API_USER_KEY,
        },
        body: JSON.stringify(validFields),
      }
    );
    const response = await res.json();
    return response;
  } catch (err) {
    return {
      status: false,
      message: "something went wrong. please try again or contact support",
    };
  }
}

export async function getLocationTotal(location) {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_ALUMNI_API}/alumni?homeLocation=${location}&perPage=1`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: process.env.REACT_APP_ABUAD_API_USER_KEY,
        },
      }
    );
    const response = await res.json();

    // return courses array if the response status is true
    // else throw an error
    if (response.status === true) {
      return response.meta.total;
    } else {
      return 0;
    }
  } catch (err) {
    return 0;
  }
}

// get the amount of coursemates
export async function getTotalCoursemates(course, graduationYear) {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_ALUMNI_API}/alumni?bscCourse=${course}&graduationYear=${graduationYear}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: process.env.REACT_APP_ABUAD_API_USER_KEY,
        },
      }
    );
    const response = await res.json();

    // return courses array if the response status is true
    // else throw an error
    if (response?.status === true) {
      return response.meta.total;
    } else {
      return 0;
    }
  } catch (err) {
    return 0;
  }
}

export async function getCoursemates(course, graduationYear) {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_ALUMNI_API}/alumni?bscCourse=${course}&graduationYear=${graduationYear}&perPage=20`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: process.env.REACT_APP_ABUAD_API_USER_KEY,
        },
      }
    );
    const response = await res.json();

    // return courses array if the response status is true
    // else throw an error
    if (response.status === true) {
      return response;
    } else {
      return [];
    }
  } catch (err) {
    return [];
  }
}

export async function getSimilarBusinesses(industry) {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_ALUMNI_API}/business?industry=${industry}&perPage=20`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: process.env.REACT_APP_ABUAD_API_USER_KEY,
        },
      }
    );
    const response = await res.json();

    // return courses array if the response status is true
    // else throw an error
    if (response.status === true) {
      return response.data;
    } else {
      return [];
    }
  } catch (err) {
    return [];
  }
}


export async function getAlumnusByName(name) {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_ALUMNI_API}/alumni?fullName=${name}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: process.env.REACT_APP_ABUAD_API_USER_KEY,
        },
      }
    );
    const response = await res.json();
    return response;
  } catch (err) {
    return {
      status: false,
    };
  }
}

// get the amount of coursemates
export async function getTotalBusiness() {
  try {
    const res = await fetch(`${process.env.REACT_APP_ALUMNI_API}/business`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: process.env.REACT_APP_ABUAD_API_USER_KEY,
      },
    });
    const response = await res.json();

    // return courses array if the response status is true
    // else throw an error
    if (response?.status === true) {
      return response.meta.total;
    } else {
      return 0;
    }
  } catch (err) {
    return 0;
  }
}

export async function getBusinesses() {
  try {
    const res = await fetch(`${process.env.REACT_APP_ALUMNI_API}/business`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: process.env.REACT_APP_ABUAD_API_USER_KEY,
      },
    });
    const response = await res.json();

    // return courses array if the response status is true
    // else throw an error
    if (response.status === true) {
      return response;
    } else {
      return [];
    }
  } catch (err) {
    return [];
  }
}

export async function getBusinessByAlumnus(alumnusId) {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_ALUMNI_API}/business?alumnusId=${alumnusId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: process.env.REACT_APP_ABUAD_API_ADMIN_KEY,
        },
      }
    );
    const response = await res.json();

    // return courses array if the response status is true
    // else throw an error
    return response; //  expecting only 1
  } catch (err) {
    return false;
  }
}

export async function findUser(endpoint = '', key = process.env.REACT_APP_ABUAD_API_USER_KEY) {

  try {
    const res = await fetch(`${process.env.REACT_APP_ALUMNI_API}/${endpoint}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': key,
      },
    })
    const response = await res.json();
    // return courses array if the response status is true
    // else throw an error
    return response

  } catch (err) {
    return {
      "status": "false"
    };
  }
}

export async function sendLoginDetails(userId = '', key = process.env.REACT_APP_ABUAD_API_ADMIN_KEY) {
  console.log(userId);
  try {
    const res = await fetch(`${process.env.REACT_APP_ALUMNI_API}/alumni?verify=${userId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': key,
      },
    })
    const response = await res.json();
    // return courses array if the response status is true
    // else throw an error
    return response

  } catch (err) {
    return {
      status: false
    };
  }
}

export async function findBusiness(endpoint = '', key = process.env.REACT_APP_ABUAD_API_USER_KEY) {

  try {
    const res = await fetch(`${process.env.REACT_APP_ALUMNI_API}/${endpoint}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': key,
      },
    })
    const response = await res.json();
    // return courses array if the response status is true
    // else throw an error
    return response

  } catch (err) {
    return {
      "status": "false"
    };
  }
}