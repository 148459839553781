import "../styles/directory.css";
import { FaBuilding, FaEdit } from "react-icons/fa";
import { IoMdAddCircle } from "react-icons/io";
import { useState, useEffect } from "react";
import Nav from "../components/Nav";
import { Link, Redirect } from "react-router-dom";
import {
  getBusinessByAlumnus,
  getBusinesses,
  getTotalBusiness,
} from "../services/fetchRequests";
import BusinessSearchResult from "../components/BusinessSearchResult";
import { useDispatch, useSelector } from "react-redux";
import { populateBusiness } from "../redux/business";
import BusinessAppBarBottom from "../components/BusinessAppBarBottom";

const BusinessDirectory = (props) => {
  const dispatch = useDispatch();
  const [totalBusiness, setTotalBusiness] = useState(0);
  const [business, setBusiness] = useState([]);
  const [alumnusBusiness, setAlumnusBusiness] = useState({status: false});
  // get profile array from redux
  const { id } = useSelector((state) => state.profile);

  //get the id

  useEffect(() => {
    Promise.all([
      getTotalBusiness(),
      getBusinesses(),
      getBusinessByAlumnus(id),
    ]).then((values) => {
      const [one, two, three] = values;
      setTotalBusiness(one);
      setBusiness(two);
      setAlumnusBusiness(three);
      if(three.status === true){
        dispatch(populateBusiness(three.data[0]));
      }
    });
  }, [id, dispatch]);

  // go to login page if not logged in
  if (props.loggedIn.value === false) {
    return <Redirect to="/" />;
  }

  return (
    <div>
      <Nav />
      <div className="px-4 space-y-6 bg-gray-50 md:px-6 lg:px-28 pt-14 pb-20">
        <div className="directory__summary">
          <h2 className="directory__summary-title">Summary</h2>
          <div className="directory__summary-inner">
            <div className="metric selected">
              <span className="title">
                <FaBuilding />
                Total businesses
              </span>
              <p className="number">{totalBusiness}</p>
            </div>

            {/* HIDE buttons if use already adds a business */}
             {alumnusBusiness.status === false ?
            <Link
              to="/business-add"
              className="flex bg-primary hover:bg-primary-light items-center justify-center px-2 py-1 rounded-sm text-gray-600 text-sm"
            >
              <span className="text-white text-4xl">
                <IoMdAddCircle />
              </span>
              <p className="ml-3 text-white text-base">Add your business</p>
            </Link>     
            : 
              ''
            }
            {alumnusBusiness.status === true && alumnusBusiness['data'][0].visible === 'true' ?
              <Link
              to="/business-edit"
              className="flex bg-primary hover:bg-primary-light items-center justify-center px-2 py-1 rounded-sm text-gray-600 text-sm"
            >
              <span className="text-white text-3xl">
                <FaEdit />
              </span>
              <p className="ml-3 text-white text-base">Edit your business</p>
            </Link>
            :
            ''
            }

            
          </div>
        </div>

        <div>
          <h2 className="mb-4 w-full col-span-full text-base text-primary font-semibold">
            Recent businesses
          </h2>
          <BusinessSearchResult result={business} />
        </div>
      </div>
      <BusinessAppBarBottom />
    </div>
  );
};

export default BusinessDirectory;
