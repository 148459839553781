import "../styles/menuPageStyles.css";
import { FaUserGraduate, FaUsers } from "react-icons/fa";
import { MdDescription, MdAccountBalance } from "react-icons/md";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";

const Menu = (props) => {
  const { username, firstName } = useSelector((state) => state.profile);

  if (props.loggedIn.value === false) {
    return <Redirect to="/" />;
  }

  return (
    <div>
      <div className="menu">
        <div className="menu__header">
          <p className="name">Hi {firstName}</p>
          <p className="id">Username: {username}</p>
          <p className="message">
            Enjoy a wide range of services provided by the Afe babalola
            university alumni
          </p>
        </div>

        <div className="menu__body">
          <Link to="/profile" className="item">
            <FaUserGraduate />
            <h2 className="title">Profile</h2>
            <p className="comment">View and edit your profile</p>
          </Link>

          <Link to="/directory" className="item">
            <FaUsers />
            <h2 className="title">Alumni Directory</h2>
            <p className="comment">
              Search and Connect with all graduates of Afe Babalola University
            </p>
          </Link>

          <div className="item">
            <MdDescription />
            <h2 className="title">
              Transcript Request{" "}
              <span className="text-xs text-white rounded-full bg-yellow-600 py-1 px-2">
                coming soon
              </span>
            </h2>
            <p className="comment">
              Request and send your transcript to organizations
            </p>
          </div>

          <Link to="/businesses" className="item">
            <MdAccountBalance />
            <h2 className="title">Business Directory</h2>
            <p className="comment">View Businesses owned by your collegues </p>
          </Link>

          {/* <div className="item">
            <MdPayment />
            <h2 className="title">
              Payments{" "}
              <span className="text-xs text-white rounded-full bg-yellow-600 py-1 px-2">
                coming soon
              </span>
            </h2>
            <p className="comment">View all payments and receipts</p>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Menu;
