import { Link } from "react-router-dom";
import businessImage from "../img/storeImage.jpg";
import { MdLocationOn } from "react-icons/md";

const BusinessSearchResult = (props) => {
  const result = props.result;

  if (result.status === true) {
    return (
      <>
        <div className="grid grid-cols-2 gap-3 md:grid-cols-3 lg:grid-cols-5">
          {result.data.map((business, i) => (
            <div
              key={business.id}
              className="flex flex-col p-4 rounded-md shadow-sm  border border-gray-100 bg-white text-center"
            >
              <img
                className="w-28 rounded-full self-center mb-1"
                src={business.image ? business.image : businessImage}
                alt="alumnus"
              />
              <span className="text-base font-medium text-gray-600">
                {business.name}
              </span>
              <span className="text-sm text-gray-400">{business.industry}</span>
              <span className="flex text-xs items-center justify-center text-primary">
                <span>
                  <MdLocationOn />
                </span>
                {business.location}
              </span>
              <Link
                className="btn-full mt-2 text-sm"
                to={{
                  pathname: `/businesses/business/${business.id}`,
                  state: { business: business },
                }}
              >
                View
              </Link>
            </div>
          ))}
        </div>
      </>
    );
  }  
  if (result.status === "idle") {
    return <div className="text-center text-sm">Input a name and search</div>;
  }  
  if (result.status === "searching") {
    return <div className="text-center text-sm">Searching</div>;
  }  
  if (result.status === false) {
    return <div className="text-center text-sm">Not found</div>;
  }  
  if (result.status === 'false') {
    return <div className="text-center text-sm">Not found</div>;
  } 

  else {
    return <div className="text-center text-sm">Searching</div>;
  }
};

export default BusinessSearchResult;
