import { createSlice } from "@reduxjs/toolkit";

export const profileSlice = createSlice({
  name: "profile",
  initialState: {
    status: "",
    id: "",
    username: "",
    firstName: "",
    middleName: "",
    surname: "",
    email: "",
    phone: "",
    dateOfBirth: "",
    sex: "",
    maritalStatus: "",
    religion: "",
    nationality: "",
    stateOfOrigin: "",
    homeLocation: "",
    graduationYear: "",
    matriculationNumber: "",
    college: "",
    department: "",
    bscSchool: "",
    bscCourse: "",
    mscSchool: "",
    mscCourse: "",
    phdSchool: "",
    phdCourse: "",
    jobTitle: "",
    placeOfWork: "",
    industryOfWork: "",
    linkedIn: "",
    createdAt: "",
    lastUpdate: "",
    visible: "",
    image: "",

    // "status": "success",
    // "id": "8987",
    // "username": "@4tade",
    // "firstName": "Olatade",
    // "middleName": null,
    // "surname": "Abiona",
    // "email": "4tade01@gmail.com",
    // "phone": "08066197110",
    // "dateOfBirth": "1993-06-25",
    // "sex": "male",
    // "maritalStatus": "single",
    // "religion": "christianity",
    // "nationality": null,
    // "stateOfOrigin": null,
    // "homeLocation": "Lagos",
    // "graduationYear": "2013",
    // "matriculationNumber": "09/sci02/001",
    // "college": null,
    // "department": null,
    // "bscSchool": "Afe Babalola University",
    // "bscCourse": "computer science",
    // "mscSchool": "eastern mediterranian",
    // "mscCourse": "Information technology",
    // "phdSchool": "abuad",
    // "phdCourse": null,
    // "jobTitle": "Software Engineer",
    // "placeOfWork": "HeGo3rip limited",
    // "industryOfWork": "Internet and Web Services",
    // "linkedIn": "olatade",
    // "createdAt": "2021-07-09 15:24:25",
    // "lastUpdate": "2021-07-09 20:24:25",
    // "enabled": "true",
    // "image": "https://api.hego3rip.com/abuad-alumni/avatar/2021//ba1ee1a688ca95921bdaa3bff11021ac.jpeg"
  },
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1;
    },
    decrement: (state) => {
      state.value -= 1;
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload;
    },
    populateProfile: (state, action) => {
      for (const i in state) {
        state[i] = action.payload[i];
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { increment, decrement, incrementByAmount, populateProfile } =
  profileSlice.actions;

export default profileSlice.reducer;
