import '../styles/alumnus.css';
import Nav from './Nav';
import { Link } from 'react-router-dom';
import userImg from '../img/userImage.png';
import { MdLocationOn } from "react-icons/md";
import { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { titleCase } from "../utils/functions";
import { Redirect } from "react-router-dom";
import { useSelector } from 'react-redux';


// get the amount of coursemates
async function getCoursemates(course, year) {
  try {
    const res = await fetch(`https://hego3rip.com/api/abuad-alumni/alumni?bscCourse=${course}&graduationYear=${year}&perPage=20`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: process.env.REACT_APP_ABUAD_API_USER_KEY,
      }
    })
    const courses = await res.json();

    // return courses array if the response status is true
    // else throw an error
    if (courses?.status === true) {
      return courses.data
    } else {
      throw new Error('something happened')
    }

  } catch (err) {
    return false
  }
}


const Alumnus = (props) => {
  const loggedIn = useSelector(state => state.loggedIn);
  const profile = props.location.state.coursemate;
  const { pathname } = useLocation();

  // set state for the alumnus coursemates;
  const [coursemates, setCoursemates] = useState([]);

  // after page load, load the alumnus' friends
  useEffect(() => {
    Promise.all([getCoursemates(profile.bscCourse, profile.graduationYear)])
      .then((values) => {
        const [one] = values;
        setCoursemates(one); // set coursemates to the returned objec
      })
  }, [profile.bscCourse]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  // go to login page if not logged in
  if (loggedIn.value === false) {
    return <Redirect to='/' />
  }

  return (
    <div>
      <Nav />
      <div className="alumnus  bg-gray-100">

        <div className="alumnus__header">
          <span className="alumnus__header-location"><MdLocationOn />{profile.homeLocation || "-"}</span>
          <div className="flex space-x-2 md:space-x-4 items-center">
            <div className="image" >
              <img src={profile.image || userImg} alt="profile" />
            </div>

            <div className="name-div">
              <p className="name" >{titleCase(profile.firstName)} {titleCase(profile.surname)}</p>
              <p className="username">{profile.username}</p>
              {profile.jobTitle ? <p className="job">{profile.jobTitle} at {profile.placeOfWork}</p> : ''}
            </div>
          </div>

          <div className="alumnus__study">
            <div className="item">
              <span className="title">Studied</span>
              <span className="value">{profile.bscCourse}</span>
            </div>

            <div className="item">
              <span className="title ">Graduated</span>
              <span className="value ">{profile.graduationYear || '-'}</span>
            </div>
          </div>

          <div className="buttons">
            <a className="btn-empty block" href={`mailto:${profile.email}`}>Email</a>
            <button className="btn-full">Connect</button>
          </div>
        </div>

        <div className="alumnus__body">
          <div className="alumnus__collegues">
            <h4 className="title p-sm">{titleCase(profile.firstName)}'s collegues</h4>

            <div className="alumnus__collegues-inner">

              {
                coursemates.map((coursemate, i) => (
                  <Link className="item" key={coursemate.id} to={{ pathname: `/directory/alumnus/${coursemate.id}`, state: { coursemate: coursemate } }} >
                    <img className="image" src={coursemate.image || userImg} alt="user" />
                    <span className="name p-xs">{titleCase(coursemate.firstName)} {titleCase(coursemate.surname)}</span>
                    <span className="username p-xs">{coursemate.username}</span>
                    <span className="location">
                      <MdLocationOn />
                      {coursemate.homeLocation || "-"}
                    </span>
                  </Link>
                ))
              }

            </div>
          </div>
        </div>

      </div>
    </div>
  );
}

export default Alumnus;
