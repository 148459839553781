import Nav from "./Nav";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { BiLoader } from "react-icons/bi";
import { MdClose } from "react-icons/md";
import "../styles/profileEditPage.css";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import industryList from "../data/job-industries";
import countryList from "../data/countries";
import stateList from "../data/states";
import { populateProfile } from "../redux/profile";
import { useHistory } from "react-router-dom";
// import { Redirect } from "react-router-dom";
import { getCourses, updateAlumnus } from "../services/fetchRequests";
import graduationYears from "../data/graduationYears";
import degrees from "../data/degrees";
import RenderImage from "./ProfileImageUpload/renderImage/RenderImage";

// component that displays message when form is submitted
function FormMessage(props) {
  const state = { ...props.state };
  const setState = props.setState;
  // const dispatch = useDispatch();

  return (
    <div
      className={`form-message border border-red-500 rounded-lg bg-white p-4 ${
        state.status ? "" : "hidden"
      }`}
    >
      {/* title and close button */}
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-1">
          <span className="text-base text-red-600">
            <AiOutlineExclamationCircle />
          </span>
          <p className="text-sm text-red-700 font-semibold">
            Something went wrong
          </p>
        </div>

        <span
          onClick={(e) => setState({ status: false })}
          className="form-message-close justify-self-end cursor-pointer"
        >
          <MdClose />
        </span>
      </div>

      {/* Error */}
      {/* {state.code ? <p className="text-xs pl-2 pt-2  text-gray-600">{state.code}</p> : ''} */}

      {/* message */}
      {state.message ? (
        <p className="text-xs pl-2 pt-2  text-gray-600">{state.message}</p>
      ) : (
        ""
      )}

      {state.code && state.code === "V-300" ? (
        <p className="text-xs pl-2 pt-2  text-gray-600">what fields</p>
      ) : (
        ""
      )}

      {state.code === "unknown" ? (
        <p className="text-xs pl-2 pt-2  text-gray-600">
          An unknown error occured.{" "}
          <a
            href={`https://wa.me/2349030009002?text=Hi%2C%20%0A%0AI%20am%20experiencing%20technical%20while%20trying%20to%20create%20an%20ABUAD%20alumni%20account`}
            alt="help"
          >
            Click here
          </a>{" "}
          to contact help{" "}
        </p>
      ) : (
        ""
      )}
    </div>
  );
}

const ProfileEdit = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  // go to login page if not logged in
  // props.loggedIn.value === false ? <Redirect to="/" /> : "";

  // get profile array from redux
  const profile = useSelector((state) => state.profile);

  const [bscCourses, setBscCourses] = useState([]);
  const [mscCourses, setMscCourses] = useState([]);
  const [errorMsg, setErrorMsg] = useState({
    status: false,
    message: false,
    code: false,
  });
  const [image, setDbImage] = useState(profile.image ?? null);

  // Get and populate all courses
  useEffect(() => {
    // get the courses from the api
    Promise.resolve(getCourses())
      .then((result) => {
        // create empty arrays to categorize courses by degree level
        const bscCourseArray = [];
        const mscCourseArray = [];

        // loop through courses and push them into their arrays
        result.forEach((element) => {
          const degreeId = element.degreeId;
          switch (degrees[degreeId].level) {
            case 1:
              bscCourseArray.push(element);
              break;
            case 2:
              mscCourseArray.push(element);
              break;
            default:
            // code block
          }
        });

        // set the courses with their hooks
        setBscCourses(bscCourseArray);
        setMscCourses(mscCourseArray);
      })
      .catch((error) => {});
  }, []);

  return (
    <div className="bg-gray-100">
      <Nav />
      <div className="profile-edit">

        <div className="h-96 bg-white mb-6 flex justify-center pb-7 pt-20  md:mx-auto md:w-96">
          <RenderImage image={image} setDbImage={setDbImage} />
        </div>

        <div className="profile-edit__details">
          <Formik
            initialValues={{
              id: profile.id,
              username: profile.username ? profile.username : "",
              firstName: profile.firstName ? profile.firstName : "",
              middleName: profile.middleName ? profile.middleName : "",
              surname: profile.surname ? profile.surname : "",
              email: profile.email ? profile.email : "",
              phone: profile.phone ? profile.phone : "",
              dateOfBirth: profile.dateOfBirth ? profile.dateOfBirth : "",
              sex: profile.sex ? profile.sex : "",
              maritalStatus: profile.maritalStatus ? profile.maritalStatus : "",
              religion: profile.religion ? profile.religion : "",
              nationality: profile.nationality ? profile.nationality : "",
              stateOfOrigin: profile.stateOfOrigin ? profile.stateOfOrigin : "",
              homeLocation: profile.homeLocation ? profile.homeLocation : "",
              graduationYear: profile.graduationYear
                ? profile.graduationYear
                : "",
              matriculationNumber: profile.matriculationNumber
                ? profile.matriculationNumber
                : "",
              college: profile.college ? profile.college : "",
              department: profile.department ? profile.department : "",
              bscSchool: profile.bscSchool ? profile.bscSchool : "",
              bscCourse: profile.bscCourse ? profile.bscCourse : "",
              mscSchool: profile.mscSchool ? profile.mscSchool : "",
              mscCourse: profile.mscCourse ? profile.mscCourse : "",
              phdSchool: profile.phdSchool ? profile.phdSchool : "",
              phdCourse: profile.phdCourse ? profile.phdCourse : "",
              jobTitle: profile.jobTitle ? profile.jobTitle : "",
              placeOfWork: profile.placeOfWork ? profile.placeOfWork : "",
              industryOfWork: profile.industryOfWork
                ? profile.industryOfWork
                : "",
              linkedIn: profile.linkedIn ? profile.linkedIn : "",
              createdAt: profile.createdAt ? profile.createdAt : "",
              lastUpdate: profile.lastUpdate ? profile.lastUpdate : "",
              enabled: profile.visible ? profile.visible : "",
              image: profile.image ? profile.image : "",
            }}
            validationSchema={Yup.object({
              linkedIn: Yup.string().trim(),
              jobTitle: Yup.string().trim(), // required
              industryOfWork: Yup.string().trim(),
              placeOfWork: Yup.string().trim(),
              phone: Yup.string()
                .trim()
                .min(6)
                .max(15)
                .matches("[0-9]+$", "Only numbers allowed")
                .required("Required"),
              email: Yup.string()
                .trim()
                .min(5)
                .email("Invalid email")
                .required(`Required`),
              homeLocation: Yup.string().trim().required("Required"),
              matriculationNumber: Yup.string()
                .trim()
                .required("Required")
                .matches(
                  "^[0-9]{2}/[a-zA-z]{3}[0-9]{2}/[0-9]{3,4}$",
                  "Should be like - 00/sms00/034"
                ),
              graduationYear: Yup.string().trim().required("Required"),
              bscSchool: Yup.string().trim(),
              bscCourse: Yup.string().trim(),
              mscSchool: Yup.string().trim(),
              mscCourse: Yup.string().trim(),
              phdSchool: Yup.string().trim(),
              phdCourse: Yup.string().trim(),
              username: Yup.string().min(3).max(12).trim().required(`Required`),
              firstName: Yup.string()
                .trim()
                .required("Required")
                .matches("[aA-zZs]+$", "Special characters not allowed"),
              middleName: Yup.string()
                .trim()
                .matches("[aA-zZs]+$", "Special characters not allowed"),
              surname: Yup.string()
                .trim()
                .required("Required")
                .matches("[aA-zZs]+$", "Special characters not allowed"),
              dateOfBirth: Yup.string().required("Required"),
              stateOfOrigin: Yup.string().trim(), // required
              sex: Yup.string(), //required
              religion: Yup.string(), // required
              maritalStatus: Yup.string(), // required
              nationality: Yup.string(), // required
            })}
            onSubmit={(values, { setSubmitting }) => {    
              const toSend = { id: profile.id};
              // put values that changed into the tosend object
              for (const value in values) {
                // if the value is not exact as in the profile and it's not an empty string
                if (values[value] !== profile[value] && values[value] !== "") {
                  toSend[value] = values[value];
                }
              }
              // test if the image is a base64...if not, don't add to the request
              const regex = new RegExp('^data:image/jpeg;base64', 'i')
              if(regex.test(image)){
                toSend['image'] = image
              }

              Promise.resolve(updateAlumnus(toSend))
                .then(function (result) {
                  if (result.status === true) {
                    dispatch(populateProfile(result.data));
                    setSubmitting(false);
                    history.push("/profile");
                  } else if (result.status === false) {
                    setErrorMsg({
                      status: true,
                      message: result.message,
                      code: result?.errorCode,
                    });
                    setSubmitting(false);
                  }
                })
                .catch((error) => {});
            }}
          >
            {({ isSubmitting }) => (
              <Form className="form">
                <div className="profile-edit__detail">
                  <h3 className="title">Professional Info</h3>
                  {/* Website */}
                  <div>
                    <div className="form-group">
                      <label className="form-group__label" htmlFor="linkedIn">
                        Website (linkedIn or personal website)
                      </label>
                      <Field
                        className="form-group__input text-base"
                        name="linkedIn"
                        type="text"
                        placeholder={profile.linkedIn ? profile.linkedIn : "-"}
                      />
                    </div>

                    <ErrorMessage
                      render={(msg) => (
                        <div className="form-group__error-message">{msg}</div>
                      )}
                      name="linkedIn"
                    />
                  </div>

                  {/* Job title */}
                  <div>
                    <div className="form-group">
                      <label className="form-group__label" htmlFor="jobTitle">
                        Job Title (Unemployed, Accountant, e.t.c)
                      </label>
                      <Field
                        className="form-group__input"
                        name="jobTitle"
                        type="text"
                        placeholder={profile.jobTitle ? profile.jobTitle : "-"}
                      />
                    </div>
                    <ErrorMessage
                      render={(msg) => (
                        <div className="form-group__error-message">{msg}</div>
                      )}
                      name="jobTitle"
                    />
                  </div>

                  {/* Industry */}
                  <div>
                    <div className="form-group">
                      <label
                        className="form-group__label"
                        htmlFor="industryOfWork"
                      >
                        What industry do you work in
                      </label>
                      <Field
                        className="form-group__input"
                        name="industryOfWork"
                        as="select"
                      >
                        {profile.industryOfWork ? (
                          <option value={profile.industryOfWork}>
                            {profile.industryOfWork}
                          </option>
                        ) : (
                          <option value="">-</option>
                        )}
                        {industryList.map((industry, i) => {
                          return profile.industryOfWork === industry ? (
                            ""
                          ) : (
                            <option value={industry} key={i}>
                              {industry}
                            </option>
                          );
                        })}
                      </Field>
                    </div>
                    <ErrorMessage
                      render={(msg) => (
                        <div className="form-group__error-message">{msg}</div>
                      )}
                      name="industryOfWork"
                    />
                  </div>

                  {/* Place of work */}
                  <div>
                    <div className="form-group">
                      <label
                        className="form-group__label"
                        htmlFor="placeOfWork"
                      >
                        Where do you work
                      </label>
                      <Field
                        className="form-group__input"
                        name="placeOfWork"
                        type="text"
                        placeholder={
                          profile.placeOfWork ? profile.placeOfWork : "-"
                        }
                      />
                    </div>
                    <ErrorMessage
                      render={(msg) => (
                        <div className="form-group__error-message">{msg}</div>
                      )}
                      name="placeOfWork"
                    />
                  </div>
                </div>

                <div className="profile-edit__detail">
                  <h3 className="title">Contact</h3>

                  {/* Phone */}
                  <div>
                    <div className="form-group">
                      <label className="form-group__label" htmlFor="phone">
                        Phone
                      </label>
                      <Field
                        className="form-group__input"
                        name="phone"
                        type="text"
                        placeholder={profile.phone ? profile.phone : "-"}
                      />
                    </div>
                    <ErrorMessage
                      render={(msg) => (
                        <div className="form-group__error-message">{msg}</div>
                      )}
                      name="phone"
                    />
                  </div>

                  {/* Email */}
                  <div>
                    <div className="form-group">
                      <label className="form-group__label" htmlFor="email">
                        Email
                      </label>
                      <Field
                        className="form-group__input"
                        name="email"
                        type="text"
                        placeholder={profile.email ? profile.email : "-"}
                      />
                    </div>
                    <ErrorMessage
                      render={(msg) => (
                        <div className="form-group__error-message">{msg}</div>
                      )}
                      name="email"
                    />
                  </div>

                  {/* Home location */}
                  <div>
                    <div className="form-group">
                      <label
                        className="form-group__label"
                        htmlFor="homeLocation"
                      >
                        Home location
                      </label>
                      <Field
                        className="form-group__input"
                        name="homeLocation"
                        type="text"
                        placeholder={
                          profile.homeLocation ? profile.homeLocation : "-"
                        }
                      />
                    </div>
                    <ErrorMessage
                      render={(msg) => (
                        <div className="form-group__error-message">{msg}</div>
                      )}
                      name="homeLocation"
                    />
                  </div>
                </div>

                <div className="profile-edit__detail">
                  <h3 className="title">Education</h3>
                  {/* Matriculation number */}
                  <div>
                    <div className="form-group">
                      <label
                        className="form-group__label"
                        htmlFor="matriculationNumber"
                      >
                        Matriculation number
                      </label>
                      <Field
                        className="form-group__input"
                        name="matriculationNumber"
                        type="text"
                        disabled={true}
                        placeholder={
                          profile.matriculationNumber
                            ? profile.matriculationNumber
                            : "-"
                        }
                      />
                    </div>
                    <ErrorMessage
                      render={(msg) => (
                        <div className="form-group__error-message">{msg}</div>
                      )}
                      name="matriculationNumber"
                    />
                  </div>

                  {/* Graduation Year */}
                  <div>
                    <div className="form-group">
                      <label
                        className="form-group__label"
                        htmlFor="graduationYear"
                      >
                        graduation year
                      </label>
                      <Field
                        className="form-group__input"
                        name="graduationYear"
                        disabled={true}
                        as="select"
                      >
                        {profile.graduationYear ? (
                          <option value={profile.graduationYear}>
                            {profile.graduationYear}
                          </option>
                        ) : (
                          <option value="">-</option>
                        )}
                        {graduationYears
                          .slice(0)
                          .reverse()
                          .map((year, i) => {
                            return profile.graduationYear === year ? (
                              ""
                            ) : (
                              <option value={year} key={i}>
                                {year}
                              </option>
                            );
                          })}
                      </Field>
                    </div>
                    <ErrorMessage
                      render={(msg) => (
                        <div className="form-group__error-message">{msg}</div>
                      )}
                      name="graduationYear"
                    />
                  </div>

                  {/* Undergraduate School */}
                  <div>
                    <div className="form-group">
                      <label className="form-group__label" htmlFor="bscSchool">
                        Undergraduate School
                      </label>
                      <Field
                        className="form-group__input"
                        name="bscSchool"
                        type="text"
                        placeholder={
                          profile.bscSchool ? profile.bscSchool : "-"
                        }
                      />
                    </div>
                    <ErrorMessage
                      render={(msg) => (
                        <div className="form-group__error-message">{msg}</div>
                      )}
                      name="bscSchool"
                    />
                  </div>

                  {/* Undergraduate course */}
                  <div>
                    <div className="form-group">
                      <label className="form-group__label" htmlFor="bscCourse">
                        Undergraduate course
                      </label>
                      <Field
                        className="form-group__input"
                        name="bscCourse"
                        type="text"
                        list="bscCourses"
                        placeholder={
                          profile.bscCourse ? profile.bscCourse : "-"
                        }
                      />
                      <datalist id="bscCourses">
                        {bscCourses
                          .sort((a, b) => a.name - b.name)
                          .map((course, i) => (
                            <option value={course.name} key={i}>
                              {course.name} {course.degree}
                            </option>
                          ))}
                      </datalist>
                    </div>
                    <ErrorMessage
                      render={(msg) => (
                        <div className="form-group__error-message">{msg}</div>
                      )}
                      name="bscCourse"
                    />
                  </div>

                  {/* Postgraduate school */}
                  <div>
                    <div className="form-group">
                      <label className="form-group__label" htmlFor="mscSchool">
                        Postgraduate School
                      </label>
                      <Field
                        className="form-group__input"
                        name="mscSchool"
                        type="text"
                        placeholder={
                          profile.mscSchool ? profile.mscSchool : "-"
                        }
                      />
                    </div>
                    <ErrorMessage
                      render={(msg) => (
                        <div className="form-group__error-message">{msg}</div>
                      )}
                      name="mscSchool"
                    />
                  </div>

                  {/* Postgraduate course */}
                  <div>
                    <div className="form-group">
                      <label className="form-group__label" htmlFor="mscCourse">
                        Postgraduate course
                      </label>
                      <Field
                        className="form-group__input"
                        name="mscCourse"
                        type="text"
                        list="mscCourses"
                        placeholder={
                          profile.mscCourse ? profile.mscCourse : "-"
                        }
                      />
                      <datalist id="mscCourses">
                        {mscCourses
                          .sort((a, b) => a.name - b.name)
                          .map((course, i) => (
                            <option value={course.name} key={i}>
                              {course.name} {course.degree}
                            </option>
                          ))}
                      </datalist>
                    </div>
                    <ErrorMessage
                      render={(msg) => (
                        <div className="form-group__error-message">{msg}</div>
                      )}
                      name="mscCourse"
                    />
                  </div>

                  {/* PHD school */}
                  <div>
                    <div className="form-group">
                      <label className="form-group__label" htmlFor="phdSchool">
                        PHD school
                      </label>
                      <Field
                        className="form-group__input"
                        name="phdSchool"
                        type="text"
                        placeholder={
                          profile.phdSchool ? profile.phdSchool : "-"
                        }
                      />
                    </div>
                    <ErrorMessage
                      render={(msg) => (
                        <div className="form-group__error-message">{msg}</div>
                      )}
                      name="phdSchool"
                    />
                  </div>

                  {/* PHD course */}
                  <div>
                    <div className="form-group">
                      <label className="form-group__label" htmlFor="phdCourse">
                        PHD topic
                      </label>
                      <Field
                        className="form-group__input"
                        name="phdCourse"
                        type="text"
                        placeholder={
                          profile.phdCourse ? profile.phdCourse : "-"
                        }
                      />
                    </div>
                    <ErrorMessage
                      render={(msg) => (
                        <div className="form-group__error-message">{msg}</div>
                      )}
                      name="phdCourse"
                    />
                  </div>
                </div>

                <div className="profile-edit__detail">
                  <h3 className="title">Personal</h3>
                  {/* Username */}
                  <div>
                    <div className="form-group">
                      <label className="form-group__label" htmlFor="username">
                        Username
                      </label>
                      <Field
                        className="form-group__input"
                        name="username"
                        type="text"
                        placeholder={profile.username ? profile.username : "-"}
                      />
                    </div>
                    <ErrorMessage
                      render={(msg) => (
                        <div className="form-group__error-message">{msg}</div>
                      )}
                      name="username"
                    />
                  </div>

                  {/* first name */}
                  <div>
                    <div className="form-group">
                      <label className="form-group__label" htmlFor="firstName">
                        First name
                      </label>
                      <Field
                        className="form-group__input"
                        name="firstName"
                        type="text"
                        placeholder={
                          profile.firstName ? profile.firstName : "-"
                        }
                      />
                    </div>
                    <ErrorMessage
                      render={(msg) => (
                        <div className="form-group__error-message">{msg}</div>
                      )}
                      name="firstName"
                    />
                  </div>

                  {/* middle name */}
                  <div>
                    <div className="form-group">
                      <label className="form-group__label" htmlFor="middleName">
                        Middle name
                      </label>
                      <Field
                        className="form-group__input"
                        name="middleName"
                        type="text"
                        pplaceholder={
                          profile.middleName ? profile.middleName : "-"
                        }
                      />
                    </div>
                    <ErrorMessage
                      render={(msg) => (
                        <div className="form-group__error-message">{msg}</div>
                      )}
                      name="middleName"
                    />
                  </div>

                  {/* surname */}
                  <div>
                    <div className="form-group">
                      <label className="form-group__label" htmlFor="surname">
                        Surname
                      </label>
                      <Field
                        className="form-group__input"
                        name="surname"
                        type="text"
                        placeholder={profile.surname ? profile.surname : "-"}
                      />
                    </div>
                    <ErrorMessage
                      render={(msg) => (
                        <div className="form-group__error-message">{msg}</div>
                      )}
                      name="surname"
                    />
                  </div>

                  {/* date of birth */}
                  <div>
                    <div className="form-group">
                      <label
                        className="form-group__label"
                        htmlFor="dateOfBirth"
                      >
                        Date of birth
                      </label>
                      <Field
                        className="form-group__input"
                        name="dateOfBirth"
                        type="date"
                        disabled={true}
                        placeholder={
                          profile.dateOfBirth ? profile.dateOfBirth : "-"
                        }
                      />
                    </div>
                    <ErrorMessage
                      render={(msg) => (
                        <div className="form-group__error-message">{msg}</div>
                      )}
                      name="dateOfBirth"
                    />
                  </div>

                  {/* stateOfOrigin */}
                  <div>
                    <div className="form-group">
                      <label
                        className="form-group__label"
                        htmlFor="stateOfOrigin"
                      >
                        State of origin
                      </label>
                      <Field
                        className="form-group__input"
                        name="stateOfOrigin"
                        as="select"
                      >
                        {profile.stateOfOrigin ? (
                          <option value={profile.stateOfOrigin}>
                            {profile.stateOfOrigin}
                          </option>
                        ) : (
                          <option value="">-</option>
                        )}
                        {stateList.map((state, i) => (
                          <option value={state} key={i}>
                            {state}
                          </option>
                        ))}
                      </Field>
                    </div>
                    <ErrorMessage
                      render={(msg) => (
                        <div className="form-group__error-message">{msg}</div>
                      )}
                      name="stateOfOrigin"
                    />
                  </div>

                  {/* sex*/}
                  <div>
                    <div className="form-group">
                      <label className="form-group__label" htmlFor="sex">
                        Sex
                      </label>
                      <Field
                        className="form-group__input"
                        name="sex"
                        as="select"
                      >
                        {profile.sex ? (
                          <option value={profile.sex}>{profile.sex}</option>
                        ) : (
                          <option value="">-</option>
                        )}

                        {["male", "female"].map((sex) =>
                          profile.sex === sex ? (
                            ""
                          ) : (
                            <option value={sex} key={sex}>
                              {sex}
                            </option>
                          )
                        )}
                      </Field>
                    </div>
                    <ErrorMessage
                      render={(msg) => (
                        <div className="form-group__error-message">{msg}</div>
                      )}
                      name="sex"
                    />
                  </div>

                  {/* religion*/}
                  <div>
                    <div className="form-group">
                      <label className="form-group__label" htmlFor="religion">
                        Religion
                      </label>
                      <Field
                        className="form-group__input"
                        name="religion"
                        as="select"
                      >
                        {profile.religion ? (
                          <option value={profile.religion}>
                            {profile.religion}
                          </option>
                        ) : (
                          <option value="">-</option>
                        )}

                        {["christianity", "paganism", "islam", "others"].map(
                          (religion) =>
                            profile.religion === religion ? (
                              ""
                            ) : (
                              <option value={religion} key={religion}>
                                {religion}
                              </option>
                            )
                        )}
                      </Field>
                    </div>
                    <ErrorMessage
                      render={(msg) => (
                        <div className="form-group__error-message">{msg}</div>
                      )}
                      name="religion"
                    />
                  </div>

                  {/* marital status*/}
                  <div>
                    <div className="form-group">
                      <label
                        className="form-group__label"
                        htmlFor="maritalStatus"
                      >
                        Marital status
                      </label>
                      <Field
                        className="form-group__input"
                        name="maritalStatus"
                        as="select"
                      >
                        {profile.maritalStatus ? (
                          <option value={profile.maritalStatus}>
                            {profile.maritalStatus}
                          </option>
                        ) : (
                          <option value="">-</option>
                        )}

                        {["single", "married"].map((maritalStatus) =>
                          profile.maritalStatus === maritalStatus ? (
                            ""
                          ) : (
                            <option value={maritalStatus} key={maritalStatus}>
                              {maritalStatus}
                            </option>
                          )
                        )}
                      </Field>
                    </div>
                    <ErrorMessage
                      render={(msg) => (
                        <div className="form-group__error-message">{msg}</div>
                      )}
                      name="maritalStatus"
                    />
                  </div>

                  {/* Nationality */}
                  <div>
                    <div className="form-group">
                      <label
                        className="form-group__label"
                        htmlFor="nationality"
                      >
                        Nationality
                      </label>
                      <Field
                        className="form-group__input"
                        name="nationality"
                        as="select"
                      >
                        {profile.nationality ? (
                          <option value={profile.nationality}>
                            {profile.nationality}
                          </option>
                        ) : (
                          <option value="">-</option>
                        )}
                        {countryList.map((country, i) => (
                          <option value={country} key={i}>
                            {country}
                          </option>
                        ))}
                      </Field>
                    </div>
                    <ErrorMessage
                      render={(msg) => (
                        <div className="form-group__error-message">{msg}</div>
                      )}
                      name="nationality"
                    />
                  </div>
                </div>

                <FormMessage state={errorMsg} setState={setErrorMsg} />

                <button
                  className="flex justify-center text-sm bg-primary items-center transition duration-300 focus:bg-primary  hover:bg-primary-light text-center text-white font-medium py-2 px-4  rounded-md focus:outline-none"
                  type="submit"
                  // disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <span className="text-lg flex items-center space-x-1">
                      <BiLoader />
                      <p className="text-sm">Loading</p>
                    </span>
                  ) : (
                    "Update profile"
                  )}
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ProfileEdit;
