import '../styles/search.css';
import {Formik, Form, Field} from 'formik';
import Nav from './Nav';
import { useState, useEffect,} from 'react';
import {BiSearchAlt} from 'react-icons/bi';
import BusinessSearchResult from './BusinessSearchResult';
import { useLocation } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { findBusiness} from '../services/fetchRequests';
import BusinessAppBarBottom from './BusinessAppBarBottom';
import { MdEmail} from "react-icons/md";
import { FaGraduationCap } from "react-icons/fa";



/**
 * 
 * @param {*} elem the element
 * @param {*} selector the selector of the parent element
 * @returns 
 */
 const getClosest = function (elem, selector) {

	// Element.matches() polyfill
	if (!Element.prototype.matches) {
	    Element.prototype.matches =
	        Element.prototype.matchesSelector ||
	        Element.prototype.mozMatchesSelector ||
	        Element.prototype.msMatchesSelector ||
	        Element.prototype.oMatchesSelector ||
	        Element.prototype.webkitMatchesSelector ||
	        function(s) {
	            var matches = (this.document || this.ownerDocument).querySelectorAll(s),
	                i = matches.length;
	            while (--i >= 0 && matches.item(i) !== this) {}
	            return i > -1;
	        };
	}

	// Get the closest matching element
	for ( ; elem && elem !== document; elem = elem.parentNode ) {
		if ( elem.matches( selector ) ) return elem;
	}
	return null;

};

const BusinessSearch = (props) => {
  const { pathname } = useLocation();
  // false, searching & idle
  const [searchResult, setSearchResult] = useState({status: 'idle'});

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect( ()=> {
    // Close the dropdown if the user clicks outside of it
    window.onclick = function(event) {
      // if the target is not the dropdown button and the parent of the target is not the dropdown button
      if (!event.target.matches('.filterDropdown') && getClosest(event.target, '.searchBar') === null ) {
        // var dropdowns = document.getElementsByClassName("nav__menu-content");
        var dropDown = document.getElementById("filterDropdown");
        dropDown?.classList.add('hidden')
        
      }
    }

  },[]);

  // go to login page if not logged in
  if(props.loggedIn === false){
    return <Redirect to='/'/>
  }

  return (
    <div>
      <Nav/>
      <div className="search pt-20  bg-gray-100">   

        <div className="searchBar relative max-w-md mx-auto">
          <Formik
            initialValues={{
              mainFilter: "service",
              mainInput: '',
            }}
            onSubmit={ (values) =>{
              setSearchResult({status: 'searching'})
              console.log(values);
              
              Promise.resolve(findBusiness(`business?${values.mainFilter}=%${values.mainInput}%`, process.env.REACT_APP_ABUAD_API_USER_KEY))
              .then((result)=>{
                setSearchResult(result)
              })
            }}
          >
            {({ values, isSubmitting }) => (
              <Form>
                  <div className="flex space-x-2 mb-4">
                      <label
                          className={`cursor-pointer flex items-center rounded px-2 py-1 bg-gray-200 transition-all ease-out duration-300  ${
                            values.mainFilter === "name"
                              ? "bg-primary shadow text-white"
                              : "text-gray-500 shadow-md hover:bg-primary-light hover:text-white"
                          } `}
                        >
                          <Field
                            className="hidden"
                            type="radio"
                            name="mainFilter"
                            value="name"
                          />
                          <div className="flex items-center">
                            <span className="mr-1">
                              <FaGraduationCap />
                            </span>
                            <p className="text-sm">Business name</p>
                          </div>
                        </label>
    
                        <label
                          className={`cursor-pointer flex items-center rounded px-2 py-1 bg-gray-200 transition-all ease-out duration-300 ${
                            values.mainFilter === "service"
                              ? "bg-primary shadow text-white"
                              : "text-gray-500 shadow-md hover:bg-primary-light hover:text-white"
                          } `}
                        >
                          <Field
                            className="hidden"
                            type="radio"
                            name="mainFilter"
                            value="service"
                          />
                          <div className="flex items-center">
                            <span className="mr-1">
                              <MdEmail />
                            </span>
                            <p className="text-sm">Service</p>
                          </div>
                        </label>

                  </div>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <label className="block text-xs font-medium text-gray-400 mb-2 md:font-semibold md:text-sm sr-only" htmlFor="mainInput">Search</label>
                    <Field className="appearance-none shadow-none border border-gray-200 rounded w-full py-2 pl-8 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-primary" name="mainInput" type="text" placeholder="Search" />
                    <span className="absolute left-0 top-0 mt-3 ml-3"> <BiSearchAlt/></span>
                  </div>
              </Form>
            )}
          </Formik>
        </div>


        <div className="pt-6 pb-20">
          <BusinessSearchResult result={searchResult}/>
        </div>

      </div>
      <BusinessAppBarBottom/>
    </div>
  );
}

export default BusinessSearch;
