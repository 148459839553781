import {Link} from 'react-router-dom';
import userImg from '../img/userImage.png';
import { titleCase } from '../utils/functions';

const AlumniSearchResult = (props) => {
  const result = props.result;


  if(result.status === true){
    return(
      <>
        <div className="grid grid-cols-2 gap-3 md:grid-cols-3 lg:grid-cols-5">
          {
          result.data.map( (coursemate, i) => (
            <div key={coursemate.id} className="flex flex-col p-4 rounded-md shadow-sm  border border-gray-100 bg-white text-center">
              <img className="w-28 rounded-full self-center mb-1" src={ coursemate.image ? coursemate.image : userImg } alt="alumnus" />
              <span className="text-sm font-medium text-gray-600">{titleCase(coursemate.firstName)} {titleCase(coursemate.surname)}</span>
              <span className="text-sm text-gray-400">{coursemate.jobTitle ? coursemate.jobTitle : `Studied: ${coursemate.bscCourse}`}</span>
              <span className="text-xs text-primary"> Graduated {coursemate.graduationYear}</span>
              <Link className="btn-full mt-2 text-sm" to={{pathname: `/directory/alumnus/${coursemate.id}`, state:{coursemate: coursemate}}}>
                View profile
              </Link>
            </div>
          )) 
        }
        </div>
      </>
      


    )
  }

  else if(result.status === 'idle'){
    return (
      <div className="text-center text-sm">
        Input a name and search
      </div>
    )
  } else if(result.status === 'searching'){
    return (
      <div className="text-center text-sm">
        Searching
      </div>
    )
  } else if(result.status === false){
    <div className="text-center text-sm">
      Not found
    </div>
  }else{
    return (
      <div className="text-center text-sm">
        Searching
      </div>
    )
  }
  
  

}

export default AlumniSearchResult;