// avatar component
import ClickAwayListener from '@mui/material/ClickAwayListener';
import {AiFillCamera} from 'react-icons/ai';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { IconButton } from '@mui/material';


import ImageCropper from '../imageCropper/ImageCropper';
import RenderImage_hook from "./RenderImage_hook";

export default function RenderImage({image, setDbImage}){

  const {
    displayImage,
    anchorRef,
    open,
    handleToggle,
    showCropper,
    handleCropper,
    setDisplayImage,
    handleClose,
    handleListKeyDown
  } = RenderImage_hook(image, setDbImage)

	return(
		<div className='grid grid-cols-1 grid-rows-1 w-full'>

			<div className="avatar-container relative grid justify-center items-center col-start-1 col-end-2 row-start-1 row-end-2 ">
				
				<div className="avatar relative w-36 h-36 rounded-full bg-purple-200 ">
					<img src={displayImage} alt="avatar" className="w-full h-full rounded-full" />
					<div className='absolute z-10 right-0  -bottom-2 bg-white rounded-full'>
					<IconButton 
						ref={anchorRef}
						id="composition-button"
						aria-controls={open ? 'composition-menu' : undefined}
						aria-expanded={open ? 'true' : undefined}
						aria-haspopup="true"
						onClick={handleToggle}
					>
						<span className="text-4xl"><AiFillCamera/></span>
					</IconButton>
				  </div>
			  </div>
				
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom-start' ? 'left top' : 'left bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                  >
                    <MenuItem onClick={handleClose}>View</MenuItem>
                    <MenuItem onClick={(event) => { handleCropper(); handleClose(event);}}>Change</MenuItem>
                    <MenuItem onClick={handleClose}>Remove</MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
			</div>

			{ showCropper && <ImageCropper handleCropper={handleCropper} setDisplayImage={setDisplayImage} setDbImage={setDbImage} /> }
		</div>
	)
}
