import { useContext, useRef, useState } from "react";
import { SnackbarContext } from '../../snackBar/snackBar';
import { generateDownload, generateBase64 } from '../utils';

const ImageCropper_hook = (handleCropper, setDisplayImage, setDbImage) =>{

  const inputRef = useRef();

  const triggerFileSelectPopup = () => inputRef.current.click();

	const setStateSnackbarContext =  useContext(SnackbarContext);

  const[image, setImage] = useState(null);
  const[croppedArea, setCroppedArea] = useState(null);
  const[crop, setCrop] = useState({x:0, y:0});
  const[zoom, setZoom] = useState(1);

  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) =>{
    console.log(croppedAreaPercentage, croppedAreaPixels);
    setCroppedArea(croppedAreaPixels);
  }

  const onSelectFile = (event) =>{
    // check if file slected
    if(event.target.files && event.target.files.length > 0){
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.addEventListener('load', () => {
        setImage(reader.result);
      })
    }
    // console.log(event)
  }

  const onDownload = () => {
			if(!image) return setStateSnackbarContext(true, 'please select an image!', 'warning');
			// generate a base64 image and store it somehow
      generateDownload(image, croppedArea )
  }

	const onSave = async () => {
		if(!image) return setStateSnackbarContext(true, 'please select an image!', 'warning');
		const croppedImage = await generateBase64(image, croppedArea);
		setDisplayImage(croppedImage); // set the value that will be displayed
    setDbImage(croppedImage); // set the value that will be sent to the database
		handleCropper();
}

	const onClear = () =>{
		if(!image) return setStateSnackbarContext(true, 'please select an image!', 'warning');
		setImage(null);
	}

  return {inputRef, image, handleCropper, setDisplayImage, onCropComplete, onSelectFile, onDownload, triggerFileSelectPopup, onSave, onClear, crop, setCrop, zoom, setZoom}

}

export default ImageCropper_hook;