import { Link } from "react-router-dom";
import userImage from "../img/userImage.png";
import "../styles/profilePageStyles.css";
import ProfileProgress from "./ProfileProgress";
import {useSelector} from 'react-redux';
import Nav from './Nav';
import { Redirect } from "react-router-dom";

const Profile = (props) => {
  const profile = useSelector(state => state.profile); // get the profile reducer

  // go to login page if not logged in
  if(props.loggedIn.value === false){
    return <Redirect to='/'/>
  }

  return (
    <div className="bg-gray-100 min-h-screen">
      <Nav/>
      <div className="profile pt-10  pb-28">
        <div className="profile__head">
          <img className="profile__image" src={profile.image ? profile.image : userImage } alt="profile_image" />
          <p className="profile__username">{profile.username}</p>
          <p className="profile__name">{profile.firstName} {profile.surname}</p>
          <p className="profile__job">{profile.jobTitle}</p>
          <ProfileProgress />
          <Link
            className="btn btn-full profile__edit-button"
            to="/profile/edit"
          >
            Edit profile
          </Link>
        </div>

        <div className="profile__details">
          <div className="profile-section">
            <p className="title">Professional</p>

            <div className="item">
              <span className="label">Website</span>
              <span className="data">{profile.linkedIn ? profile.linkedIn : '-' }</span>
            </div>

            <div className="item">
              <span className="label">Job title</span>
              <span className="data">{profile.jobTitle ? profile.jobTitle : '-' }</span>
            </div>

            <div className="item">
              <span className="label">Industry</span>
              <span className="data">{profile.industryOfWork ? profile.industryOfWork : '-' }</span>
            </div>

            <div className="item">
              <span className="label">Place of work</span>
              <span className="data">{profile.placeOfWork ? profile.placeOfWork : '-' }</span>
            </div>
          </div>

          <div className="profile-section">
            <p className="title">Contact</p>

            <div className="item">
              <span className="label">Phone</span>
              <span className="data">{profile.phone ? profile.phone : '-' }</span>
            </div>

            <div className="item">
              <span className="label">Email</span>
              <span className="data">{profile.email ? profile.email : '-' }</span>
            </div>

            <div className="item">
              <span className="label">Home Location</span>
              <span className="data">{profile.homeLocation ? profile.homeLocation : '-' }</span>
            </div>
          </div>

          <div className="profile-section">
            <p className="title">Education</p>

            <div className="item">
              <span className="label">Matriculation number</span>
              <span className="data">{profile.matriculationNumber ? profile.matriculationNumber : '-' }</span>
            </div>

            <div className="item">
              <span className="label">Graduation year</span>
              <span className="data">{profile.graduationYear ? profile.graduationYear : '-' }</span>
            </div>

            <div className="item">
              <span className="label">Undergraduate School</span>
              <span className="data">{profile.bscSchool ? profile.bscSchool : '-' }</span>
            </div>

            <div className="item">
              <span className="label">Undergraduate course</span>
              <span className="data">{profile.bscCourse ? profile.bscCourse : '-' }</span>
            </div>

            <div className="item">
              <span className="label">Postgraduate school</span>
              <span className="data">{profile.mscSchool ? profile.mscSchool : '-' }</span>
            </div>

            <div className="item">
              <span className="label">Postgraduate course</span>
              <span className="data">{profile.mscCourse ? profile.mscCourse : '-'}</span>
            </div>

            <div className="item">
              <span className="label">PHD school</span>
              <span className="data">{profile.phdSchool ? profile.phdSchool : '-'}</span>
            </div>

            <div className="item">
              <span className="label">PHD topic</span>
              <span className="data">{profile.phdCourse ? profile.phdCourse : '-'}</span>
            </div>
          </div>

          <div className="profile-section">
            <p className="title">Personal</p>

            <div className="item">
              <span className="label">Username</span>
              <span className="data">{profile.username ? profile.username : '-'}</span>
            </div>

            <div className="item">
              <span className="label">First name</span>
              <span className="data">{profile.firstName ? profile.firstName : '-'}</span>
            </div>

            <div className="item">
              <span className="label">Middle name</span>
              <span className="data">{profile.middleName ? profile.middleName : '-'}</span>
            </div>


            <div className="item">
              <span className="label">Surname</span>
              <span className="data">{profile.surname ? profile.surname : '-'}</span>
            </div>

            <div className="item">
              <span className="label">Religion</span>
              <span className="data">{profile.religion ? profile.religion : '-'}</span>
            </div>

            <div className="item">
              <span className="label">Date of birth</span>
              <span className="data">{profile.dateOfBirth ? profile.dateOfBirth : '-'}</span>
            </div>

            <div className="item">
              <span className="label">Sex</span>
              <span className="data">{profile.sex ? profile.sex : '-'}</span>
            </div>

            <div className="item">
              <span className="label">Marital status</span>
              <span className="data">{profile.maritalStatus ? profile.maritalStatus : '-'}</span>
            </div>
          </div>
        
        </div>
      </div>
    </div>
  );
};

export default Profile;
