import * as React from 'react';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { createContext } from 'react';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const SnackbarContext = createContext();

export default function CustomizedSnackbars({children}) {
  // const [open, setOpen] = React.useState(false);

  const [stateSnackBar, setStateSnackBar] = React.useState({
    open: false,
    severity: "",
    message: "",
  });

  const setStateSnackBarContext = (open, message, severity) => setStateSnackBar({...stateSnackBar, open, message, severity});

  const handleClose = () => setStateSnackBar({...stateSnackBar, open: false});

  const { open, severity, message} = stateSnackBar;

  return (
    <SnackbarContext.Provider value={setStateSnackBarContext}>
      <Stack spacing={2} sx={{ width: '100%' }}>
        <Snackbar anchorOrigin={{vertical: "top", horizontal: "center"}} open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {message}
          </Alert>
        </Snackbar>
      </Stack>
      {children}
    </SnackbarContext.Provider>
    
  );
}
