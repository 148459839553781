import "../styles/App.css";
import Login from "./Login";
import Menu from "./Menu";
import Profile from "./Profile";
import ProfileEdit from "./ProfileEdit";
import Alumnus from "./Alumnus";
import Search from "./Search";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Directory from "./Directory";
import { useSelector } from "react-redux";
import BusinessDirectory from "../pages/BusinessDirectory";
import BusinessEdit from "../pages/BusinessEdit";
import BusinessSearch from "./BusinessSearch";
import SingleBusiness from "./SingleBusiness";
import BusinessAdd from "../pages/BusinessAdd";
import AccountCheck from "./Account_check";

function App() {
  const loggedIn = useSelector((state) => state.loggedIn);
  return (
    <Router className="App">
      {/* <Nav/> */}
      <Switch>
        <Route path="/" exact component={Login}></Route>
        <Route
          path="/menu"
          component={() => <Menu loggedIn={loggedIn} />}
        ></Route>
        <Route
          path="/profile/edit"
          component={() => <ProfileEdit loggedIn={loggedIn} />}
        ></Route>
        <Route
          path="/profile"
          component={() => <Profile loggedIn={loggedIn} />}
        ></Route>
        <Route path="/directory/alumnus/:id" component={Alumnus}></Route>
        <Route
          path="/directory/search"
          component={() => <Search loggedIn={loggedIn} />}
        ></Route>
        <Route
          path="/directory"
          component={() => <Directory loggedIn={loggedIn} />}
        ></Route>
        <Route path="/businesses/business/:id" component={SingleBusiness}></Route>
        <Route
          path="/businesses/search"
          component={() => <BusinessSearch loggedIn={loggedIn} />}
        ></Route>
        <Route
          path="/business-add"
          component={() => <BusinessAdd loggedIn={loggedIn} />}
        ></Route>
        <Route
          path="/businesses"
          component={() => <BusinessDirectory loggedIn={loggedIn} />}
        ></Route>
        <Route
          path="/business-edit"
          component={() => <BusinessEdit loggedIn={loggedIn} />}
        ></Route>
      </Switch>
      <AccountCheck/>
    </Router>
  );
}

export default App;
