import '../styles/profileProgress.css';
import {useSelector} from 'react-redux';
import {AiOutlineExclamationCircle} from 'react-icons/ai';

const ProfileProgress = () =>{
  const profile = useSelector(state => state.profile) // get the profile object

  var count = 0; // create a count for the required fields that are filled
  const requiredFields = ['username', 'firstName', 'surname', 'email', 'phone', 'dateOfBirth', 'sex', 'maritalStatus', 'religion', 'nationality', 'stateOfOrigin', 'homeLocation', 'graduationYear', 'matriculationNumber', 'bscSchool', 'bscCourse', 'jobTitle', 'placeOfWork', 'industryOfWork', 'linkedIn', 'image'];
  const requiredFieldsLength = requiredFields.length
  // loop through the required fields and check if they are set in the user object 
  requiredFields.forEach( (field)=> {
    if (profile[field]){
      count ++; // increase the count of the required fields that are set;
    }
  })
  // get the percentage of the required fields that have a value
  const percentage = Math.round((count/requiredFieldsLength)*(100/1));

  return(
    <div className="progress">
      <div className="progress__title">
        <p className="progress__title-name">Profile state</p>

        <p id="bar-percent" className="progress__title-percent">{percentage}%</p>
      </div>

      <div className="progress__inner">
        <div id="bar-width" className="progress__inner-bar" style={{width: `${percentage}%`}}></div>
      </div>
      { percentage < 90 ? 
      <div className="flex flex-col space-x-2 mt-2">
        <span className="text-red-600 text-xl mx-auto">
          <AiOutlineExclamationCircle/>
        </span>
        <p className="text-sm text-red-600">Your profile must be at least 90% complete to enjoy most of our services</p> 
      </div> : ''  }
    </div>
  )
}

export default ProfileProgress;