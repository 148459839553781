import Button from '@mui/material/Button';
import Slider from '@mui/material/Slider';
import { AiOutlineCloseCircle} from 'react-icons/ai';
import Cropper from "react-easy-crop";
import ImageCropper_hook from './ImageCropper_hook';

export default function ImageCropper ({handleCropper, setDisplayImage, setDbImage}){

  const {
    inputRef, image, onCropComplete, onSelectFile, triggerFileSelectPopup, onSave, onClear, crop, setCrop, zoom, setZoom
  } = ImageCropper_hook(handleCropper, setDisplayImage, setDbImage);


  return (
    <div className="z-10 col-start-1 col-end-2 row-start-1 row-end-2 bg-black bg-opacity-50 h-full w-full  relative">
			
			<span onClick={handleCropper} className='border-3 border-white z-20 text-3xl absolute top-4 right-4 text-white cursor-pointer hover:text-red-600'>
				<AiOutlineCloseCircle/>
			</span>
      
			<div className="container-cropper  h-5/6 ">
        {image ? (
        <>
          <div className="cropper h-5/6 relative">
            <Cropper image={image} crop={crop} zoom={zoom} aspect={1} onCropChange={setCrop} onZoomChange={setZoom} onCropComplete={onCropComplete}/>
          </div>

          <div className="slider h-1/6 flex items-center justify-center w-3/6 m-auto">
            <Slider min={1} max={3} step={0.1} value={zoom} onChange={(e, zoom) => setZoom(zoom)}/>
          </div>
        </>
        ) : null}
        
      </div>

      <div className="h-1/6 bg-white flex items-center justify-center space-x-2">
        <input className="hidden" type='file' accept='image/*' onChange={onSelectFile} ref={inputRef}/>

        <Button variant='contained' color='primary' onClick={triggerFileSelectPopup}>Choose</Button>
        {/* <Button variant='contained' color='secondary' onClick={onDownload}>Download</Button> */}
        <Button variant='contained' color='secondary' onClick={() => onClear()}>Clear</Button>
        <Button variant='contained' color='secondary' onClick={onSave}>Save</Button>
      </div>
    </div>
  )
}
