import '../styles/appBarBottom.css';
import {FaHome, FaSearch} from 'react-icons/fa';
import { Link, matchPath, useHistory } from 'react-router-dom';

const BusinessAppBarBottom = () => {
  const history = useHistory();
  // console.log(matchPath(history.location.pathname, '/profile')?.isExact);

  function checkPath(path){
    return matchPath(history.location.pathname, path)?.isExact
  }

  return (
    <div className="appbar-b">
      <Link to='/businesses' className={`appbar-b__link ${checkPath('/businesses') ? 'selected' : '' }`} ><FaHome/><span className="appbar-b__link-name">Dashboard</span></Link>
      <Link to='/businesses/search' className={`appbar-b__link ${checkPath('/businesses/search') ? 'selected' : '' }`}><FaSearch/><span className="appbar-b__link-name">Search</span></Link>
    </div>
  );
}

export default BusinessAppBarBottom;